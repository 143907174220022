import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useState } from 'react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { v4 as uuidv4 } from 'uuid';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import useCreateVirtualUser from '@client/page/management/appointment/employee/logic/use-create-virtual-user.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';

interface VirtualUserInput {
  id: string;
  firstName: string;
  colorCode: string;
}

export default function CreateVirtualUserDialog(props: { onClose: () => void }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const companyId = useCompanyId();

  const [create] = useCreateVirtualUser();

  const [errorMessage, setErrorMessage] = useState<Record<string, boolean>>({});
  const [virtualUsers, setVirtualUsers] = useState<VirtualUserInput[]>([
    { id: uuidv4(), firstName: '', colorCode: '#000000' },
  ]);

  const addNewUser = () => {
    setVirtualUsers([...virtualUsers, { id: uuidv4(), firstName: '', colorCode: '#000000' }]);
  };

  const updateUser = (input: { id: string; firstName?: string; colorCode?: string }) => {
    const { id, firstName, colorCode } = input;

    const newList = virtualUsers.map((virtualUser) => {
      if (virtualUser.id === id) {
        return {
          ...virtualUser,
          firstName: firstName ?? virtualUser.firstName,
          colorCode: colorCode ?? virtualUser.colorCode,
        };
      }
      return virtualUser;
    });

    setVirtualUsers(newList);
  };

  const onSubmit = () => {
    const checkFirstnameDuplicate = findDuplicateFirstName(virtualUsers);
    if (checkFirstnameDuplicate) {
      setErrorMessage({ [checkFirstnameDuplicate]: true });
      return;
    }

    create({
      variables: {
        companyID: companyId,
        input: virtualUsers
          .filter((item) => item.firstName !== '')
          .map((virtualUser) => ({
            firstName: virtualUser.firstName,
            colorCode: virtualUser.colorCode,
          })),
      },
    })
      .then(() => {
        props.onClose();
        showAlert({
          status: 'success',
          message: t('user.create-virtual-user.alert.success.message', 'Successfully created virtual user'),
          title: t('user.create-virtual-user.alert.success.title', 'Success'),
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          message: e.message,
          title: t('user.create-virtual-user.alert.error.title', 'Error'),
        });
      });
  };

  return (
    <Card>
      <CardHeader title={t('user.virtual-user-create.header', 'Create Virtual User')} onCrossButton={props.onClose} />

      <CardContent>
        {virtualUsers.map((virtualUser) => (
          <FormItem key={virtualUser.id} title="">
            <div className="flex item-center justify-center gap-2">
              <TextInput
                type="text"
                label="name"
                value={virtualUser.firstName}
                className="grow"
                placeholder={t('user.virtual-user-create.form-input.name.placeholder', 'Name')}
                name={`${virtualUser.firstName}-name`}
                onChange={(e) => {
                  updateUser({ id: virtualUser.id, firstName: e.target.value });
                }}
              />
              <div className="w-8 h-8 flex justify-center items-center overflow-hidden rounded-full">
                <input
                  type="color"
                  className="min-w-20 h-20"
                  name={`${virtualUser.firstName}-colorCode`}
                  value={virtualUser.colorCode}
                  onChange={(e) => {
                    updateUser({ id: virtualUser.id, colorCode: e.target.value });
                  }}
                />
              </div>
            </div>
            {errorMessage[virtualUser.id] && (
              <p className="text-red-600 text-xs ml-1 mt-1">
                {t('user.virtual-user-create.duplicate-error', 'This name is already in use.')}
              </p>
            )}
          </FormItem>
        ))}
        <FormItem title="">
          <div className="w-full flex justify-center mt-6">
            <PrimaryButton
              className="rounded-xl bg-blue-600"
              onClick={() => {
                addNewUser();
              }}
            >
              + {t('user.virtual-user-create.button.add', 'Add')}
            </PrimaryButton>
          </div>
        </FormItem>
      </CardContent>

      <CardFooter>
        <PrimaryButton onClick={onSubmit}>{t('common.button-create', 'Create')}</PrimaryButton>
        <SecondaryButton>{t('common.button.cancel', 'Cancel')}</SecondaryButton>
      </CardFooter>
    </Card>
  );
}

function findDuplicateFirstName(users: VirtualUserInput[]): string | null {
  const nameMap = new Map<string, string>(); // Lưu firstName và id tương ứng

  for (const user of users) {
    if (user.firstName == '') {
      continue;
    }
    if (nameMap.has(user.firstName)) {
      return user.id; // Trả về id của phần tử bị trùng
    }
    nameMap.set(user.firstName, user.id);
  }

  return null; // Không có trùng lặp
}
