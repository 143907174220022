import { graphql } from '@client/graphql/types';
import { useQuery, useSubscription } from '@apollo/client';
import { OnlineOrderDashboardFilter } from '@client/graphql/types/graphql.ts';
import { useEffect } from 'react';
import { captureException } from '@sentry/browser';

const QUERY = graphql(`
  query OnlineOrderDashboard($branchID: ID!, $filter: OnlineOrderDashboardFilter!) {
    onlineOrdersDashboard(branchID: $branchID, filter: $filter) {
      ...OnlineOrderListItem
    }
  }
`);

const SUBSCRIPTION = graphql(`
  subscription OnlineOrderDashboardSubscription($branchID: ID!) {
    onUpdateOnlineOrder(branchID: $branchID) {
      branchID
      onlineOrderID
      timestamp
    }
  }
`);

export default function useOnlineOrderDashboardPage(branchId: string, filter: OnlineOrderDashboardFilter) {
  const query = useQuery(QUERY, {
    variables: {
      branchID: branchId,
      filter: filter,
    },
  });

  const { data } = useSubscription(SUBSCRIPTION, {
    variables: {
      branchID: branchId,
    },
    shouldResubscribe: true,
  });

  useEffect(() => {
    if (data) {
      query.refetch().catch(captureException);
    }
  }, [query.refetch, data, query]);

  return query;
}
