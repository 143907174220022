import { Link, useMatches, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  BanknotesIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  ClockIcon,
  Cog6ToothIcon,
  FilmIcon,
  HomeIcon,
  KeyIcon,
  LinkIcon,
  PrinterIcon,
  RectangleStackIcon,
  ShoppingBagIcon,
  Square3Stack3DIcon,
  TruckIcon,
  UsersIcon,
  WalletIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { ComponentProps, ComponentType, ReactNode, useEffect, useState } from 'react';
import { ArrowsPointingInIcon } from '@heroicons/react/24/solid';
import { UserCompanyPermission } from '@client/graphql/types/graphql.ts';
import FocusedBranchSelection from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-selection.tsx';
import { captureException } from '@sentry/browser';
import getImageLink from '@packages/core/image-source/get-image-link.ts';
import checkPermission from '@client/module/utils/check-permission.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useTolgee, useTranslate } from '@tolgee/react';
import { motion } from 'framer-motion';
import { ChevronLeftIcon, CloudIcon, UserGroupIcon, UserIcon } from '@heroicons/react/16/solid';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useCompanyHubpageLink } from '@client/page/management/company-settings/logic/use-company-hubpage-link.ts';
import NavigationItemButton from '@client/app/component/navigation-item-button.tsx';
import useCheckAdminRole from '@client/module/auth/check-role-hook.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { forceUpdateServiceWorker } from '@client/app/logic/force-update.ts';

export interface NavigationItem {
  title: string;
  href: string;
  icon: ComponentType<ComponentProps<'svg'>>;
  permission?: UserCompanyPermission;
  query?: Record<string, string> | (() => Record<string, string>);
  admin?: boolean;
  superAdmin?: boolean;
  children?: NavigationItem[];
  wrapper?: (wrappedComponent: ReactNode) => JSX.Element;
}

const HubPageCustomNavigationItem = (wrappedComponent: ReactNode) => {
  const link = useCompanyHubpageLink();
  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        window.open(link, '_blank', 'noopener, noreferrer');
      }}
    >
      {wrappedComponent}
    </div>
  );
};

//HOME PAGE
const dashboardHome: NavigationItem[] = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: HomeIcon,
  },
  {
    title: 'Business Card',
    href: '',
    icon: LinkIcon,
    wrapper: HubPageCustomNavigationItem,
  },
  {
    title: 'Customer',
    href: '/customer',
    icon: UsersIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
    admin: true,
  },
];

const reservationHome: NavigationItem[] = [
  {
    title: 'Schedule',
    href: '/table-reservation/list',
    icon: CalendarIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Report',
    href: '/table-reservation/analytics',
    icon: ChartBarSquareIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'History',
    href: '/table-reservation/history',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
];

const onlineOrderHome: NavigationItem[] = [
  {
    title: 'Orders',
    href: '/online-order/dashboard',
    icon: WalletIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'Menu',
    href: '/menu',
    icon: Square3Stack3DIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'History',
    href: '/online-order/history',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
];

const appointmentHome: NavigationItem[] = [
  {
    title: 'Appointment List',
    href: '/appointment/list',
    icon: CalendarIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
  {
    title: 'Service Menu',
    href: '/appointment/service',
    icon: Square3Stack3DIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
  {
    title: 'Employee',
    href: '/appointment/employee',
    icon: UserIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
  {
    title: 'History',
    href: '/appointment/history',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
];

const customerHome: NavigationItem[] = [
  {
    title: 'Customer',
    href: '/customer',
    icon: UsersIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
];

const featureHome: NavigationItem[] = [
  {
    title: 'Banner',
    href: '/banners',
    icon: FilmIcon,
    permission: UserCompanyPermission.ManageBanners,
  },
  {
    title: 'Invoice',
    href: '/invoices',
    icon: BanknotesIcon,
    permission: UserCompanyPermission.ManageInvoices,
  },
];

// SETTINGS PAGE
const generalSetting: NavigationItem[] = [
  {
    title: 'Users',
    href: '/user-company-roles',
    icon: UsersIcon,
    permission: UserCompanyPermission.ManageUsers,
  },
  {
    title: 'Team',
    href: '/team',
    icon: UserGroupIcon,
    permission: UserCompanyPermission.ManageUsers,
  },
  {
    title: 'Location',
    href: '/branches',
    icon: RectangleStackIcon,
    permission: UserCompanyPermission.ManageBranches,
  },
  {
    title: 'Company',
    href: '/settings/company',
    icon: ArrowsPointingInIcon,
    permission: UserCompanyPermission.ManageTables,
  },
  {
    title: 'Opening time',
    href: '/settings/opening-time',
    icon: ClockIcon,
    permission: UserCompanyPermission.ManageOpeningTime,
  },
  {
    title: 'Holiday',
    href: '/settings/holiday',
    icon: CloudIcon,
    permission: UserCompanyPermission.ManageOpeningTime,
  },
  {
    title: 'API Key',
    href: '/api-key/internal',
    icon: KeyIcon,
    superAdmin: true,
  },
  {
    title: 'HubPage',
    href: '/settings/hubpage',
    icon: LinkIcon,
  },
];

const reservationSetting: NavigationItem[] = [
  {
    title: 'General',
    href: '/table-reservation/preferences/general',
    icon: AdjustmentsHorizontalIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Advanced',
    href: '/table-reservation/preferences/advanced',
    icon: WrenchScrewdriverIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Custom notification',
    href: '/table-reservation/preferences/notification',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Time',
    href: '/table-reservation/preferences/opening-time',
    icon: ClockIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
];

const integrationSettings: NavigationItem[] = [
  {
    title: 'Google',
    href: '/settings/integration/google',
    icon: ArrowsPointingInIcon,
  },
];

const onlineOrderSetting: NavigationItem[] = [
  {
    title: 'General',
    href: '/online-order/setting/general',
    icon: AdjustmentsHorizontalIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'Pick up',
    href: '/online-order/setting/pick-up',
    icon: ShoppingBagIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'Delivery',
    href: '/online-order/setting/delivery',
    icon: TruckIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'Printer',
    href: '/online-order/setting/printer',
    icon: PrinterIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
];

const appointmentSetting: NavigationItem[] = [
  {
    title: 'General',
    href: '/appointment/configuration/general',
    icon: AdjustmentsHorizontalIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
  {
    title: 'Time',
    href: '/appointment/configuration/opening-time',
    icon: ClockIcon,
    permission: UserCompanyPermission.ManageNailAppointment,
  },
];

const printerSetting: NavigationItem[] = [
  {
    title: 'Epson Server Direct Print',
    href: '/printer/setting/epson-server-direct-print',
    icon: PrinterIcon,
  },
];

enum NavigationMode {
  WORKING_MODE,
  SETTING_MODE,
}

function NavigationContent(props: { closeSidebar?: () => void }) {
  const matches = useMatches();
  const navigate = useNavigate();
  const company = useFocusedCompany();
  const { t } = useTranslate();
  // save on local storage, not save on state like this
  const [navigationMode, setNavigationMode] = useState<NavigationMode>(() => {
    const currentPage = localStorage.getItem('navigationModeOfCurrentPage');
    if (currentPage) {
      return currentPage === NavigationMode.SETTING_MODE.toString()
        ? NavigationMode.SETTING_MODE
        : NavigationMode.WORKING_MODE;
    }

    const savedMode = localStorage.getItem('navigationMode');
    return savedMode === NavigationMode.SETTING_MODE.toString()
      ? NavigationMode.SETTING_MODE
      : NavigationMode.WORKING_MODE;
  });

  const currentLocation = (path: string): boolean => {
    for (const match of matches.slice(4, 5)) {
      if (match.pathname === path) {
        return true;
      }
    }
    return false;
  };

  const changeMode = () => {
    if (navigationMode === NavigationMode.WORKING_MODE) {
      setNavigationMode(NavigationMode.SETTING_MODE);
      localStorage.setItem('navigationMode', NavigationMode.SETTING_MODE.toString());
    }
    if (navigationMode === NavigationMode.SETTING_MODE) {
      setNavigationMode(NavigationMode.WORKING_MODE);
      localStorage.setItem('navigationMode', NavigationMode.WORKING_MODE.toString());
    }
  };

  return (
    <ul role="list" className="flex flex-1 flex-col justify-between gap-y-7">
      <div className="flex flex-col gap-y-7">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => {
            navigate('/company-selection');
          }}
        >
          {company.logo && <img src={getImageLink(company.logo)} alt={company.name} className="w-6 h-6 rounded" />}
          <p className="text-lg font-medium text-white">{company.name}</p>
        </div>

        {navigationMode === NavigationMode.WORKING_MODE && (
          <>
            <FocusedBranchSelection />
            <div className="flex flex-col gap-3 -ml-4">
              <NavigationItemList
                title="Dashboard"
                items={dashboardHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
              <NavigationItemList
                title="Reservation"
                items={reservationHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
              <NavigationItemList
                title="Online order"
                items={onlineOrderHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
              <NavigationItemList
                title="Appointment"
                items={appointmentHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
              <NavigationItemList
                title="Customer"
                items={customerHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
              <NavigationItemList
                title="Features"
                items={featureHome}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.WORKING_MODE}
              />
            </div>
          </>
        )}

        {navigationMode === NavigationMode.SETTING_MODE && (
          <>
            <li>
              <div
                onClick={() => {
                  changeMode();
                }}
                className={classNames(
                  'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                  'text-gray-100 bg-gray-700 hover:bg-gray-500 hover:text-gray-100 cursor-pointer'
                )}
              >
                <ArrowUturnLeftIcon
                  className={classNames('h-5 w-6 shrink-0 text-white group-hover:text-white', {
                    'text-white': currentLocation('/settings'),
                  })}
                  aria-hidden="true"
                />
                <span>{t('navigation-content.setting.return-button', 'Back')}</span>
              </div>
            </li>
            <div className="flex flex-col gap-3 -ml-4">
              <NavigationItemList
                title="General"
                items={generalSetting}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />
              <NavigationItemList
                title="Reservation"
                items={reservationSetting}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />
              <NavigationItemList
                title="Online Order"
                items={onlineOrderSetting}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />

              <NavigationItemList
                title="Appointment"
                items={appointmentSetting}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />

              <NavigationItemList
                title="Integration"
                items={integrationSettings}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />

              <NavigationItemList
                title="Printer"
                items={printerSetting}
                closeSidebar={props.closeSidebar}
                navigationMode={NavigationMode.SETTING_MODE}
              />
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col gap-y-6">
        {navigationMode === NavigationMode.WORKING_MODE && (
          <div className="mt-auto flex space-x-4">
            <p className="text-lg text-white font-medium">{t('navigation-content.Language.title', 'Language')}:</p>
            <LanguageIcon language="English" languageCode="en" country="us" />
            <LanguageIcon language="Germany" languageCode="de" country="de" />
            <LanguageIcon language="Vietnamese" languageCode="vi" country="vn" />
          </div>
        )}
        {navigationMode === NavigationMode.WORKING_MODE && (
          <li>
            <div
              onClick={() => {
                changeMode();
              }}
              className={classNames(
                'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-gray-500 hover:text-white',
                {
                  'bg-gray-500 text-white': currentLocation('/settings'),
                }
              )}
            >
              <Cog6ToothIcon
                className={classNames('h-6 w-6 shrink-0 text-white group-hover:text-white', {
                  'text-white': currentLocation('/settings'),
                })}
                aria-hidden="true"
              />
              Setting
            </div>
          </li>
        )}
        <SecondaryButton
          onClick={() => {
            forceUpdateServiceWorker().catch((e) => void e);
          }}
        >
          Force update
        </SecondaryButton>
      </div>
    </ul>
  );
}

function LanguageIcon(props: { language: string; languageCode: string; country: string }) {
  const { changeLanguage } = useTolgee(['language']);

  const changeLanguageCallback = (lng: string) => {
    changeLanguage(lng).catch(captureException);
    localStorage.setItem('language', lng);
  };
  return (
    <button
      onClick={() => {
        changeLanguageCallback(props.languageCode);
      }}
      className={props.languageCode === localStorage.getItem('language') ? 'bg-gray-500 rounded px-1' : ''}
    >
      <span>
        <img src={`https://flagcdn.com/16x12/${props.country}.png`} width="20" height="16" alt={props.country} />
      </span>
    </button>
  );
}

function NavigationItemList(props: {
  title?: string;
  items: NavigationItem[];
  closeSidebar?: () => void;
  navigationMode: NavigationMode;
}) {
  const items = props.items;
  const matches = useMatches();
  const { t } = useTranslate();
  const { isSuperAdmin } = useCheckAdminRole();

  const featTitle = props.title ?? '';
  const [subMenuOpen, setSubMenuOpen] = useState(() => {
    const currentSection = localStorage.getItem('currentSection');
    return currentSection == props.title;
  });

  const { auth } = useAuth();
  const company = useFocusedCompany();

  const currentLocation = (path: string): boolean => {
    const additionalPath = path.match(/\//g)?.length ?? 0;

    for (const match of matches.slice(3 + additionalPath, 4 + additionalPath)) {
      if (match.pathname === path) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const savedState = localStorage.getItem(`submenu-${featTitle}`);
    if (savedState === 'true') {
      setSubMenuOpen(true);
    }
  }, [featTitle]);

  const filterAdminRole = items.filter((item) => {
    if (item.superAdmin && !isSuperAdmin) {
      return false;
    }
    return true;
  });

  const filterItem = filterAdminRole.filter((item) => checkPermission(item.permission, company.id, auth));

  const currentPage = () => {
    localStorage.setItem('navigationModeOfCurrentPage', props.navigationMode.toString());
    localStorage.setItem('currentSection', props.title ?? '');
  };

  if (filterItem.length == 0) {
    return <></>;
  }

  if (props.title == 'Dashboard') {
    return (
      <li>
        <div className="flex items-center cursor-pointer pb-4">
          <span className="text-lg text-white font-medium pl-4">
            {t('navigation-content.' + props.title + '.title', props.title)}
          </span>
        </div>

        <ul role="list" className=" space-y-1">
          {filterItem.map((item, index) => {
            const navigationItem = (
              <NavigationItemButton
                key={index}
                item={item}
                onClick={() => {
                  props.closeSidebar && props.closeSidebar();
                  currentPage();
                }}
                currentLocation={currentLocation}
                featTitle={featTitle}
              />
            );

            if (item.wrapper) return item.wrapper(navigationItem);

            return navigationItem;
          })}
        </ul>
      </li>
    );
  }

  return (
    <li>
      {props.title && (
        <div
          className="flex items-center cursor-pointer pb-4 pt-2"
          onClick={() => {
            setSubMenuOpen(!subMenuOpen);
          }}
        >
          {subMenuOpen ? (
            <ChevronDownIcon
              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600 ml-2"
              aria-hidden="true"
            />
          ) : (
            <ChevronLeftIcon
              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600 ml-2 transform rotate-180"
              aria-hidden="true"
            />
          )}
          <span className="text-lg text-white font-medium ">
            {t('navigation-content.' + props.title + '.title', props.title)}
          </span>
        </div>
      )}
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: 'fit-content',
              }
            : {
                height: 0,
              }
        }
        className="flex h-0 flex-col pl-14 text-[0.8rem] font-normal overflow-hidden"
      >
        <ul role="list" className="-mx-8 space-y-1">
          {filterItem.map((item) => (
            <li key={item.title}>
              {item.children ? (
                <motion.div
                  animate={
                    subMenuOpen
                      ? {
                          height: 'fit-content',
                        }
                      : {
                          height: 0,
                        }
                  }
                  className="flex h-0 flex-col  text-[0.8rem] font-normal overflow-hidden"
                >
                  <NavigationItemList
                    title={item.title}
                    items={item.children}
                    closeSidebar={props.closeSidebar}
                    navigationMode={props.navigationMode}
                  />
                </motion.div>
              ) : (
                <Link
                  to={item.href}
                  onClick={() => {
                    props.closeSidebar && props.closeSidebar();
                    currentPage();
                  }}
                  className={classNames(
                    currentLocation(item.href)
                      ? 'bg-gray-500 text-white'
                      : 'text-white hover:text-white hover:bg-gray-500',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold max-w-56'
                  )}
                >
                  <item.icon
                    className={classNames(
                      currentLocation(item.href) ? 'text-white' : 'text-gray-500 group-hover:text-white',
                      'h-6 w-6 shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  <span> {t('navigation-content.' + featTitle + '.item.' + item.title, item.title)}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </motion.ul>
    </li>
  );
}

export default NavigationContent;
