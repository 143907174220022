import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation setGoogleCalendarService(
    $integrationID: ID!
    $service: GoogleCalendarSyncService!
    $enable: Boolean!
    $branchID: ID!
  ) {
    setGoogleCalendarService(integrationID: $integrationID, service: $service, enable: $enable, branchID: $branchID)
  }
`);

export function useSetGoogleCalendarService() {
  return useMutation(MUTATION, {
    refetchQueries: ['googleIntegrations'],
  });
}
