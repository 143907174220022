import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useTranslate } from '@tolgee/react';

export default function SearchInput(props: { onChange: (searchString: string) => void }) {
  const { t } = useTranslate();
  return (
    <div className="max-w-xl min-w-sm border border-1.5 rounded-lg flex flex-1 items-center px-1">
      <div className="w-full">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="flex w-full">
          <div className="pointer-events-none inset-y-0 left-0 flex items-center px-2">
            <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </div>
          <div className="grow">
            <input
              id="search"
              name="search"
              type="search"
              placeholder={t('common.input.search.placeholder', 'Type to search')}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
              className="w-full border-none bg-white border-transparent focus:border-transparent focus:ring-0 text-gray-900
               placeholder:text-gray-400 sm:text-sm/6 max-h-[36px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
