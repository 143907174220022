import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  UserNotificationData,
} from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-data.ts';
import { useTranslate } from '@tolgee/react';

export default function UserNotificationTable(props: { loading?: boolean; items: UserNotificationData[] | undefined }) {
  const { items } = props;
  const { t } = useTranslate();
  if (!items) {
    return <div>Empty data</div>;
  }

  return (
    <>
      <Table
        loading={props.loading}
        data={items}
        build={(item) => <TableGroupRow key={item.id} userNotification={item} />}
      >
        <TableHeaderColumn className='w-auto'>{t('user-notification.list-page.table.column.title', 'Titel')}</TableHeaderColumn>
        <TableHeaderColumn className='w-full'>{t('user-notification.list-page.table.column.message', 'Nachricht')}</TableHeaderColumn>
        <TableHeaderColumn className='w-auto'>{t('user-notification.list-page.table.column.create-at', 'Erstellt bei')}</TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { userNotification: UserNotificationData }) {
  const { userNotification } = props;
  const { t } = useTranslate();

  const link = userNotification.link;
  let onClickHandler: (() => void) | undefined;
  if (link) {
    onClickHandler = () => {
      window.location.replace(link);
    };
  } else {
    onClickHandler = undefined;
  }
  const textColorClass = (() => {
    switch (userNotification.data?.color) {
      case 'GREEN':
        return 'text-green-500'; // Tailwind CSS class for green text
      case 'RED':
        return 'text-red-500'; // Tailwind CSS class for red text
      case 'YELLOW':
        return 'text-yellow-500'; // Tailwind CSS class for yellow text
      default:
        return ''; // Default color if none match
    }
  })();
  const notificationType = userNotification.data?.type ?? '';
  const name = userNotification.data?.name ?? '';
  const price = userNotification.data?.price ?? '';
  const time = userNotification.data?.time ?? '';
  let message = '';
  switch (notificationType) {
    //appointment
    case 'NEW_APPOINTMENT':
      message = t('user-notification.list-page.message.new_appointment.message', { name, price, time });
      break;
    case 'CANCELLED_APPOINTMENT':
      message = t('user-notification.list-page.message.cancelled_appointment.message', { name, price, time });
      break;

    //order
    case 'NEW_ONLINE_ORDER':
      message = t('user-notification.list-page.message.new_online_order.message', { name, price, time });
      break;

    //reservation
    case 'WAITING_RESERVATION':
      message = t('user-notification.list-page.message.waiting_reservation.message', { name, time });
      break;
    case 'CANCELLED_RESERVATION':
      message = t('user-notification.list-page.message.cancelled_reservation.message', { name, time });
  }


  return (
    <>
      <TableRow key={userNotification.id} className="hover:bg-gray-100" onClick={onClickHandler}>
        <TableRowColumn className="py-6 max-sm:py-8 max-sm:leading-5 tracking-wider	">
          <p
            className={`w-full font-semibold ${textColorClass}`}>{t('user-notification.list-page.row.title.' + notificationType.toLowerCase(),
            notificationType)}</p>
        </TableRowColumn>
        <TableRowColumn className="max-sm:leading-5 tracking-wider">
          <p className="w-full">{message}</p>
        </TableRowColumn>
        <TableRowColumn className="max-sm:leading-5 tracking-wider">
          <p className="w-full text-gray-500">
            {lodash.capitalize(moment(parseInt(userNotification.createdAt)).fromNow())}
          </p>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={userNotification.link ?? ''}>
            <ChevronRightIcon className="w-7 text-gray-400" />
          </Link>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
