import useGetVirtualUsers from '@client/page/management/appointment/create/logic/use-get-virtual-users.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useTranslate } from '@tolgee/react';
import Spinner from '@packages/ui/spinner.tsx';
import { useCallback, useEffect, useState } from 'react';
import useUpdateAppointment from '@client/page/management/appointment/detail/logic/use-update-appointment.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import EmployeeDot from '@client/page/management/appointment/employee/component/employee-dot.tsx';
import classNames from 'classnames';

interface UserCompanyRole {
  id: string;
  user: {
    id: string;
    firstName: string;
    colorCode: string;
  };
}

export default function VirtualUserSelectionDepend(props: {
  appointmentId: string;
  userId: string;
  userFirstName?: string;
}) {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const { data, loading } = useGetVirtualUsers(companyId);
  const [update] = useUpdateAppointment();

  const [userData, setUserData] = useState<UserCompanyRole[]>([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!data) {
      return;
    }

    setUserData(
      data.simpleCompanyUsers.map((item) => ({
        id: item.id,
        user: {
          id: item.user.id,
          firstName: item.user.firstName,
          colorCode: item.user.colorCode,
        },
      }))
    );
  }, [data, props.userFirstName]);

  const handleSearchBar = useCallback(
    (searchString: string) => {
      if (!data) {
        return;
      }

      setSearchString(searchString);

      setUserData(
        data.simpleCompanyUsers.filter((user) => {
          if (user.user.firstName.toLowerCase().includes(searchString.toLowerCase().trim())) {
            return true;
          }
          return false;
        })
      );
    },
    [data, setUserData]
  );

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <>Empty user data</>;
  }

  const handleClick = (userId: string) => {
    if (userId == props.userId) {
      return;
    }

    update({
      variables: {
        id: props.appointmentId,
        userId: userId,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          message: t(
            'appointment.update.employee.alert.success.message',
            'Your appointment has been updated successfully.'
          ),
          title: t('appointment.update.employee.alert.success.title', 'Success'),
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          message: e.message,
          title: t('appointment.update.employee.alert.success.title', 'Error'),
        });
      });
  };

  const isExist = data.simpleCompanyUsers.find((user) => user.user.id == props.userId);
  const currentUserName =
    props.userFirstName ?? data.simpleCompanyUsers.find((user) => user.user.id == props.userId)?.user.firstName ?? '';
  const currentColorCode =
    data.simpleCompanyUsers.find((user) => user.user.id == props.userId)?.user.colorCode ?? '#D1D5DB';

  return (
    <Menu>
      <MenuButton
        className={
          'flex justify-center items-center gap-1 focus:outline-none rounded-md bg-white font-semibold' +
          ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2 py-2 text-sm max-w-48'
        }
        onKeyDown={(e) => {
          if (e.key === ' ') {
            // Prevent the dropdown from closing when Space is pressed
            e.preventDefault();
          }
        }}
      >
        <EmployeeDot colorCode={currentColorCode} />
        <p className={classNames(`truncate w-full `, !isExist && 'text-gray-500')}>
          {props.userFirstName == '' ? 'Not selected' : currentUserName}
        </p>
      </MenuButton>

      <MenuItems
        anchor="bottom start"
        className={
          'focus:outline-none absolute min-w-28 mt-1 z-50 rounded-md border border-gray-300  p-1 text-sm/6 bg-white'
        }
      >
        <div className="text-gray-700 border-b border-1 border-gray-300">
          <input
            type="search"
            value={searchString}
            name="search"
            className="w-full rounded-md border-none border-transparent focus:border-transparent focus:ring-0 text-sm"
            placeholder={t('appontment.appointment-create.user.search.placeholder', 'Search')}
            onChange={(e) => {
              handleSearchBar(e.target.value);
            }}
          />
        </div>
        <div className="max-h-56 overflow-y-scroll no-scrollbar">
          <MenuItem>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleClick('');
              }}
              className={`group flex w-full items-center gap-2  py-2 px-1 hover:bg-slate-100`}
            >
              <p className={`text-gray-700 border-l-4 px-2 cursor-pointer`}>
                {t('appontment.appointment-create.user.not-selected', 'Remove selection')}
              </p>
            </div>
          </MenuItem>
          {userData.map((user) => {
            const colorCode = user.user.colorCode;
            return (
              <MenuItem key={user.id}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(user.user.id);
                  }}
                  className={`group flex w-full items-center gap-2  py-2 px-1 hover:bg-slate-100`}
                >
                  <p className={`text-gray-700 border-l-4 px-2 cursor-pointer`} style={{ borderLeftColor: colorCode }}>
                    {user.user.firstName}
                  </p>
                </div>
              </MenuItem>
            );
          })}
        </div>
      </MenuItems>
    </Menu>
  );
}
