import { Auth } from '@client/module/auth/auth-hook.ts';
import { authClient } from '@client/module/auth/auth-client.ts';
import { captureException } from '@sentry/browser';

export function getAuth(): Auth | null {
  const authRaw = localStorage.getItem('auth');

  if (!authRaw) {
    return null;
  } else {
    return JSON.parse(authRaw) as Auth;
  }
}

export function saveAuthStatus(authStatus: Auth) {
  localStorage.setItem('auth', JSON.stringify(authStatus));
}

export async function getValidAccessToken(retry: number): Promise<string | null> {
  const authStatus = getAuth();
  if (!authStatus) {
    return null;
  }

  let accessToken = authStatus.accessToken;

  // If the token is expired, we refresh it
  const now = new Date().getTime();
  const expiresAt = new Date(authStatus.accessTokenExpiresAt);
  if (now > expiresAt.getTime()) {
    try {
      const result = await authClient.refreshToken(authStatus.refreshToken);

      if (result === null) {
        // Refresh token is invalid
        clearAuthStatus();
      } else {
        // Update the access token
        accessToken = result.accessToken;

        saveAuthStatus({
          ...authStatus,
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          accessTokenExpiresAt: new Date(now + (result.expiresIn - 5) * 1000),
        });
      }
    } catch (error) {
      captureException(error);

      if (retry > 0) {
        // Sleep 1 second before retry
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return getValidAccessToken(retry - 1);
      }

      return null;
    }
  }

  return accessToken;
}

export function clearAuthStatus() {
  localStorage.clear();
}
