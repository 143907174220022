import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import useCreateInternalAPIKey from '@client/page/api-key/internal/create/logic/internal-api-key-create-mutation.ts';
import useFormData from '@client/module/utils/use-form-data.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from 'ui/src/button/secondary-button';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useState } from 'react';
import { captureException } from '@sentry/browser';

interface Form {
  description: string;
}

const InternalApiKeyCreatePage = (props: { onClose: () => void }) => {
  const [apiKey, setApiKey] = useState<string | null>(null);

  const [create, { loading }] = useCreateInternalAPIKey();
  const { showAlert } = useNotificationCenter();

  const { handle, data } = useFormData<Form>({ description: '' });

  const handler = () => {
    create({
      variables: {
        input: {
          description: data.description,
        },
      },
    })
      .then((result) => {
        if (result.data) {
          setApiKey(result.data.generateInternalAPIKey);
        }
      })
      .catch(() => {
        showAlert({
          title: 'Error',
          message: 'An error occurred while creating the API key',
          status: 'error',
          timeout: 5000,
        });
      });
  };

  return (
    <Card>
      <CardHeader title="Create internal API key" />

      <CardContent>
        <FormLayout>
          <FormItem title={'Description'} className="max-w-md">
            <TextInput
              type="text"
              label="description"
              value={data.description}
              name="description"
              placeholder="This value can not be changed later"
              onChange={handle}
            />
          </FormItem>

          {apiKey && (
            <FormItem title={'API Key'} className="max-w-md">
              <div className="flex gap-2">
                <TextInput
                  className="max-w-xl"
                  type="text"
                  label="API Key"
                  value={apiKey || ''}
                  name="apiKey"
                  onChange={() => void 0}
                  placeholder="API Key"
                  disabled
                />

                <SecondaryButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(apiKey || '')
                      .then(() => {
                        showAlert({
                          status: 'success',
                          title: 'API Key copied',
                          message: 'The API key has been copied to the clipboard',
                        });
                      })
                      .catch(captureException);
                  }}
                >
                  Copy
                </SecondaryButton>
              </div>
            </FormItem>
          )}
        </FormLayout>
      </CardContent>

      <CardFooter>
        {!apiKey && (
          <PrimaryButton onClick={handler} loading={loading}>
            Create
          </PrimaryButton>
        )}
        <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
      </CardFooter>
    </Card>
  );
};

export default InternalApiKeyCreatePage;
