import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import FormItem from 'ui/src/form/form-item';
import CardHeader from '@packages/ui/card/card-header';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import {
  DaysOfWeek,
  OpeningTimeItemFragment,
  OpeningTimeServiceType,
} from '@client/graphql/types/graphql.ts';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useTranslate } from '@tolgee/react';
import {
  useCreateManyOpeningTime,
} from '@client/page/management/opening-time/opening-time-create/logic/use-create-many-opening-time.ts';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/browser';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';

interface OpeningTimeCreateManyForm {
  openTime: string;
  closeTime: string;
  note: string;
  isActive: boolean;
  daysOfWeek: DaysOfWeek[];
}


function timeToMinutes(time: string): number {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

export default function OpeningTimeCreateMany(props: {
  onClose: () => void
  service: OpeningTimeServiceType
  existingOpeningTimes?: OpeningTimeItemFragment[]
}) {
  const { t } = useTranslate();
  const { onClose, service, existingOpeningTimes } = props;

  const daysOfWeek = [
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
    DaysOfWeek.Sunday,
  ];

  const { branch } = useFocusedBranchContext();
  const [createManyTime, { loading }] = useCreateManyOpeningTime();

  // const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  // const validationError = validationErrors(applicationErrors);
  const form = useForm<OpeningTimeCreateManyForm>(
    {
      defaultValues: {
        openTime: '',
        closeTime: '',
        note: '',
        isActive: true,
        daysOfWeek: [],
      },
      mode: 'onChange',
      criteriaMode: 'all',
    },
  );
  const { register, handleSubmit, control, setError, watch } = form;
  const onSubmit = async (data: OpeningTimeCreateManyForm) => {
    try {
      await createManyTime({
        variables: {
          input: {
            branchId: branch?.id ?? '',
            service: service,
            daysOfWeek: data.daysOfWeek,
            note: data.note,
            isActive: data.isActive,
            closeTime: data.closeTime,
            openTime: data.openTime,
          },
        },
      });
      onClose();
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof OpeningTimeCreateManyForm,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true },
          );
        }
      }

      captureException(err);
    }
  };

  const openTime = watch('openTime');
  const closeTime = watch('closeTime');
  // Function to check for conflicts
  const checkConflicts = (days: DaysOfWeek[]): DaysOfWeek[] => {
    if (!openTime || !closeTime || days.length === 0) return [];

    const newOpenMinutes = timeToMinutes(openTime);
    const newCloseMinutes = timeToMinutes(closeTime);
    const conflictingDays: DaysOfWeek[] = [];

    for (const existingTime of existingOpeningTimes ?? []) {
      const existingOpenMinutes = timeToMinutes(existingTime.openTime);
      const existingCloseMinutes = timeToMinutes(existingTime.closeTime);

      const overlapDays = days.filter(day =>
        existingTime.dayOfWeek.includes(day),
      );

      if (overlapDays.length > 0) {
        if (
          (newOpenMinutes >= existingOpenMinutes && newOpenMinutes < existingCloseMinutes) ||
          (newCloseMinutes > existingOpenMinutes && newCloseMinutes <= existingCloseMinutes) ||
          (newOpenMinutes <= existingOpenMinutes && newCloseMinutes >= existingCloseMinutes)
        ) {
          conflictingDays.push(...overlapDays);
        }
      }
    }

    return [...new Set(conflictingDays)];
  };

  return (
    <>
      <Card>
        <CardHeader title={t('reservation.setting.create-opening-time.title')} />
        <CardContent>
          <form
            className="space-y-6"
            onSubmit={() => {
              handleSubmit(onSubmit);
            }}
          >
            <FormItem className="max-w-md" title={t('reservation.setting.create-opening-time.open-time')}>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                type="time"
                {...register('openTime', {
                  deps: ['closeTime'],
                  required: t('opening-time.create.form-input.open-time.validation.required', 'Open time is required'),
                  validate: (openTime, form) => {
                    if (openTime > form.closeTime) {
                      return t(
                        'create-form.form-input.open-time.validation.invalid',
                        'Open time must be before close time',
                      );
                    }
                  },
                })}
              />
              {form.formState.errors.openTime && (
                <p className="text-red-500 text-xs pt-1">{form.formState.errors.openTime.message}</p>
              )}
            </FormItem>

            <FormItem className="max-w-md" title={t('reservation.setting.create-opening-time.close-time')}>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                type="time"
                {...register('closeTime', {
                  deps: ['openTime'],
                  required: t('opening-time.create.form-input.close-time.validation.required', 'Close time is required'),
                  validate: (closeTime, form) => {
                    if (closeTime < form.openTime) {
                      return t(
                        'create-form.form-input.close-time.validation.invalid',
                        'Close time must be after open time',
                      );
                    }
                  },
                })}
              />
              {form.formState.errors.closeTime && (
                <p className="text-red-500 text-xs pt-1">{form.formState.errors.closeTime.message}</p>
              )}
            </FormItem>

            <FormItem className="max-w-md capitalize " title={t('common.day-of-week.title')}>

              <div className="grid grid-cols-2 gap-2">
                {daysOfWeek.map((day) => (
                  <div className="" key={day}>
                    <label key={day} className="mr-4">
                      <input
                        type="checkbox"
                        value={day}
                        {...register('daysOfWeek', {
                          validate: (value) => {
                            if (value.length === 0) {
                              return t('opening-time.create.form-input.days-of-week.validation.required', 'Opening days is required');
                            }

                            const conflicts = checkConflicts(value);

                            if (conflicts.length > 0) {
                              const conflictMessage = t('opening-time.create.form-input.days-of-week.validation.conflict', 'Conflict detected in');
                              const conflictDays = conflicts.map(day => t(`opening-time.days.${day}`, day));
                              return `${conflictMessage}: ${conflictDays.join(', ')}`;
                            }

                            return true;
                          },
                        })}
                        className="form-checkbox text-indigo-600 rounded"
                      />
                      <span className="text-sm">
                          {' '}
                        {t('create-form.form-input.opening-time.day-of-week.' + day, day)}
                        </span>
                    </label>
                  </div>
                ))}
              </div>
              {form.formState.errors.daysOfWeek && (
                <p className="text-red-500 text-xs pt-4">{form.formState.errors.daysOfWeek.message}</p>
              )}
            </FormItem>


            <FormItem className="max-w-md" title={t('opening-time.create.form-input.toggle.active','Active')}>
              <Controller name="isActive" control={control}
                          render={({ field: { onChange, value } }) => (
                            <ToggleInput
                              value={value}
                              onChange={onChange}
                            />
                          )} />

            </FormItem>

            <FormItem className="max-w-md" title={t('common.form-input.note')}>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                type="text"
                {...register('note')}
              />
            </FormItem>
          </form>
        </CardContent>

        <div className="bg-gray-50 px-2 py-3 flex justify-end rounded-b-lg space-x-2
         md:px-4 md:space-x-4 md:py-5 sm:px-6">
          <SecondaryButton
            className="w-32"
            onClick={() => {
              onClose();
            }}
          >
            {t('common.button.cancel')}
          </SecondaryButton>
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32"
            onClick={() => {
              handleSubmit(onSubmit)().catch(captureException);
            }}
            loading={loading}
          >
            {t('common.button.create')}
          </PrimaryButton>
        </div>
      </Card>
    </>
  );
}

