import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query simpleCompanyUsers($companyID: ID!) {
    simpleCompanyUsers(companyID: $companyID) {
      id
      user {
        id
        username
        firstName
        email
        colorCode
      }
    }
  }
`);

export default function useGetVirtualUsers(companyID: string) {
  return useQuery(QUERY, {
    variables: { companyID: companyID },
  });
}
