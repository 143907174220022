import { RouteObject } from 'react-router-dom';
import CustomerList from '@client/page/management/customer/customer-list/customer-list.tsx';
import CustomerDetail from '@client/page/management/customer/customer-detail/customer-detail.tsx';

export const CustomerRoute: RouteObject = {
  path: 'customer',
  children: [
    {
      path: '',
      element: <CustomerList />,
    },
    {
      path: ':customerId',
      element: <CustomerDetail />,
    },
  ],
};
