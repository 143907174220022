import { Bars3Icon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import classNames from 'classnames';
import useSignOut from '@client/module/auth/use-signout.ts';
import UserNotificationButton from '@client/module/user-notification/user-notification-button.tsx';
import { captureException } from '@sentry/browser';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useTranslate } from '@tolgee/react';

const userNavigation = [{ title: 'Your profile', href: '/user-profile' }];

function NavigationBar(props: { setSidebarOpen: (value: ((prevState: boolean) => boolean) | boolean) => void }) {
  const { auth } = useAuth();
  const { signOut } = useSignOut();
  const { t } = useTranslate();

  return (
    <div
      className="sticky top-0 z-20 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => {
          props.setSidebarOpen(true);
        }}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div>
        <p className="text-xs text-gray-300">
          version: {import.meta.env.VITE_APP_VERSION}
        </p>
      </div>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex flex-1" />

        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <div className="flex justify-center">
            <span className="hidden md:block mt-3 ">{t('navigation-bar.notification.label', 'Notification')}</span>
            <UserNotificationButton />
          </div>
          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <span className="flex lg:items-center">
                <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  {auth?.username ?? 'Unknown'}
                </span>
                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg
               ring-1 ring-gray-900/5 focus:outline-none"
              >
                {userNavigation.map((item) => (
                  <Menu.Item key={item.title}>
                    {({ focus }) => {
                      return (
                        <a
                          href={item.href}
                          className={classNames(
                            focus ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                          )}
                        >
                          {item.title}
                        </a>
                      );
                    }}
                  </Menu.Item>
                ))}
                <Menu.Item>
                  {({ focus }) => {
                    return (
                      <button
                        onClick={() => {
                          signOut().catch(captureException);
                        }}
                        className={classNames(
                          focus ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900',
                        )}
                      >
                        Sign Out
                      </button>
                    );
                  }}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
