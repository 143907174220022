import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import CardContent from '@packages/ui/card/card-content';
import TextInput from '@packages/ui/form/input/text-input';
import FormItem from '@packages/ui/form/form-item';
import Card from '@packages/ui/card/card';
import CardHeader from '@packages/ui/card/card-header';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardSection from '@packages/ui/card/card-section.tsx';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '@tolgee/react';
import { Controller, useForm } from 'react-hook-form';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/browser';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import ValidationDialog from '@client/page/user-company-role/user-create/component/validation-dialog.tsx';
import Center from '@packages/ui/center.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import useResetUserPassword from './logic/use-reset-password';
import useUserCompanyRoleUpdate from './logic/use-user-company-role-update';
import UserDelete from './component/user-delete';
import useUserDetail from './logic/use-user-detail';

enum UserCompanyPermission {
  ManageBanners = 'MANAGE_BANNERS',
  ManageBranches = 'MANAGE_BRANCHES',
  ManageCompany = 'MANAGE_COMPANY',
  ManageNailAppointment = 'MANAGE_NAIL_APPOINTMENT',
  ManageOnlineOrder = 'MANAGE_ONLINE_ORDER',
  ManageOnlineOrderBranchConfiguration = 'MANAGE_ONLINE_ORDER_BRANCH_CONFIGURATION',
  ManageOpeningTime = 'MANAGE_OPENING_TIME',
  ManageTableReservation = 'MANAGE_TABLE_RESERVATION',
  ManageUsers = 'MANAGE_USERS',
  ManageHubpage = 'MANAGE_HUBPAGE',
}

interface FormData {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  permission: UserCompanyPermission[];
  colorCode: string;
}

export default function UserDetail() {
  const userID = useParams().userID ?? '';
  const companyID = useCompanyId();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const { showAlert } = useNotificationCenter();

  //fetch
  const { data, refetch } = useUserDetail(userID, companyID);

  //update
  const [update, { loading }] = useUserCompanyRoleUpdate();

  const form = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, control, setError, watch } = form;

  useEffect(() => {
    if (data) {
      form.reset({
        id: data.user?.id,
        username: data.user?.username,
        firstName: data.user?.firstName,
        lastName: data.user?.lastName,
        email: data.user?.email,
        status: data.user?.status,
        permission: data.userCompanyRole.permission,
        colorCode: data.user?.colorCode,
      });
    }
  }, [data, form]);

  const onSubmit = async (updateData: FormData) => {
    try {
      const result = await update({
        variables: {
          companyID: companyID,
          input: {
            userId: userID,
            email: updateData.email.trim(),
            firstName: updateData.firstName.trim(),
            lastName: updateData.lastName.trim(),
            username: updateData.username.trim(),
            status: updateData.status,
            permission: updateData.permission,
            colorCode: updateData.colorCode,
          },
        },
      });
      if (result.data) {
        showAlert({
          title: t('user-company-role.update.success.title', 'Successfully'),
          message: t('user-company-role.update.success.message', 'User has been update successfully'),
          status: 'success',
        });
        void refetch();
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        setOpen(false);
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof FormData,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true }
          );
        }
      } else {
        setOpen(true);
      }
      captureException(err);
    }
  };

  const [resetPassword] = useResetUserPassword();
  // const error = loadingError ?? updateError ?? resetPasswordError;

  const handleResetPassword = () => {
    resetPassword({
      variables: {
        companyID: companyID,
        userID: userID,
      },
    })
      .then((value) => {
        alert("User's reset password is: " + (value.data?.resetUserPassword ?? ''));
        navigate('..');
      })
      .catch(() => {
        alert('Failed to reset password');
      });
  };

  const [selectedCount, setSelectedCount] = useState(0);
  const permissions = watch('permission', []);
  useEffect(() => {
    setSelectedCount(permissions.length);
  }, [permissions]);

  return (
    <>
      <Helmet>
        <title>Update User</title>
        <meta name="description" content="This page allows you to create a user" />
      </Helmet>
      <div className="space-y-4 2xl:max-w-[1500px]">
        <ContentLayout className="">
          <FullPrimaryContent>
            <Card>
              <CardHeader title={t('user.user-detail.header', 'Edit user')} withBackButton={true}>
                <PrimaryButton
                  id="create-button"
                  data-testid="create-button"
                  className="w-32"
                  disabled={!form.formState.isDirty}
                  onClick={() => {
                    handleSubmit(onSubmit)().catch(captureException);
                  }}
                >
                  {t('common.button.update')}
                </PrimaryButton>
              </CardHeader>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div className="flex flex-col xl:flex-row gap-4">
          <ContentLayout className="xl:basis-2/3">
            <FullPrimaryContent>
              <div className="space-y-4">
                <Card>
                  <CardHeader title="User data" />

                  <CardContent>
                    {loading ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : (
                      <form
                        className={'space-y-6'}
                        onSubmit={() => {
                          handleSubmit(onSubmit);
                        }}
                      >
                        <FormItem title={'Username'} className="max-w-xl">
                          <Controller
                            rules={{
                              required: t(
                                'user-company-role.create.username.validation.required',
                                'Username is required'
                              ),
                              maxLength: {
                                value: 20,
                                message: t(
                                  'user-company-role.create.username.validation.max',
                                  'Username must be less than 20 characters'
                                ),
                              },
                              pattern: {
                                value: /^\S.*\S$/, // Regex to ensure no leading or trailing spaces
                                message: t(
                                  'user-company-role.create.username.validation.no-space',
                                  'Business name cannot have leading or trailing spaces'
                                ),
                              },
                            }}
                            name={'username'}
                            control={control}
                            render={({ field }) => (
                              <TextInput
                                data-testid="Username-input"
                                type="text"
                                label="Username"
                                value={field.value}
                                placeholder="Username"
                                error={form.formState.errors.username?.message}
                                onChange={field.onChange}
                                name=""
                              />
                            )}
                          />
                        </FormItem>

                        <FormItem title={'Email'} className="max-w-xl">
                          <Controller
                            rules={{
                              required: t('user-company-role.create.email.validation.required', 'Email is required'),
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t(
                                  'user-company-role.create.email.validation.invalid',
                                  'Invalid email address, example:email@gmail.com'
                                ),
                              },
                            }}
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <TextInput
                                data-testid="email-input"
                                type="text"
                                label="Email"
                                value={field.value}
                                name=""
                                error={form.formState.errors.email?.message}
                                placeholder="email"
                                onChange={field.onChange}
                              />
                            )}
                          />
                        </FormItem>

                        <div className="flex flex-col xl:flex-row gap-4 xl:max-w-xl">
                          <FormItem title={'First Name'} className="max-w-md basis-1/2">
                            <Controller
                              rules={{
                                required: t(
                                  'user-company-role.create.firstname.validation.required',
                                  'Firstname is required'
                                ),
                                maxLength: {
                                  value: 20,
                                  message: t(
                                    'user-company-role.create.firstname.validation.max',
                                    'Firstname must be less than 20 characters'
                                  ),
                                },
                              }}
                              name="firstName"
                              control={control}
                              render={({ field }) => (
                                <TextInput
                                  data-testid="firstName-input"
                                  type="text"
                                  label="Firstname"
                                  value={field.value}
                                  name=""
                                  error={form.formState.errors.firstName?.message}
                                  placeholder="firstname"
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          </FormItem>

                          <FormItem title={'Last Name'} className="max-w-md basis-1/2">
                            <Controller
                              rules={{
                                required: t(
                                  'user-company-role.create.lastname.validation.required',
                                  'Lastname is required'
                                ),
                                maxLength: {
                                  value: 20,
                                  message: t(
                                    'user-company-role.create.lastname.validation.max',
                                    'Lastname must be less than 20 characters'
                                  ),
                                },
                              }}
                              name="lastName"
                              control={control}
                              render={({ field }) => (
                                <TextInput
                                  data-testid="lastName-input"
                                  type="text"
                                  label="Lastname"
                                  value={field.value}
                                  name=""
                                  error={form.formState.errors.lastName?.message}
                                  placeholder="lastname"
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          </FormItem>
                        </div>
                        <FormItem title={'Color code'} className="max-w-xl">
                          <Controller
                            name="colorCode"
                            control={control}
                            render={({ field }) => (
                              <div className="flex gap-2 items-center">
                                <input
                                  type="color"
                                  className=""
                                  name={'colorCode'}
                                  value={field.value}
                                  onChange={field.onChange}
                                />

                                <p className="text-sm text-gray-600">{field.value}</p>
                              </div>
                            )}
                          />
                        </FormItem>
                      </form>
                    )}
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader title="Extra actions" />
                  <CardContent>
                    <div className="space-y-4">
                      <UserDelete userId={userID} />

                      <CardSection
                        title="Reset password"
                        descriptions="This action will immediately create a new random password for user. The new password will be displayed in the alert."
                      >
                        <SecondaryButton className="w-32" data-testid="update-button" onClick={handleResetPassword}>
                          Reset password
                        </SecondaryButton>
                      </CardSection>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </FullPrimaryContent>
          </ContentLayout>

          <ContentLayout className="grow">
            <FullPrimaryContent>
              {/*User role*/}
              <Card>
                <CardHeader title={'Permissions'}>
                  {form.formState.errors.permission && (
                    <div className="text-red-500 text-sm">{form.formState.errors.permission.message}</div>
                  )}
                  <p className="text-sm">
                    {t('user.user-permission.selected-count', 'Selected')}: {selectedCount}
                  </p>
                </CardHeader>

                <CardTableContent>
                  <div className="max-h-72 overflow-y-auto">
                    {Object.values(UserCompanyPermission).map((permission) => (
                      <div key={permission} className="flex px-5 py-4 gap-6 truncate border-b ">
                        <input
                          type={'checkbox'}
                          value={permission}
                          {...register('permission', {
                            required: t(
                              'user-company-role.create.permission.validation.required',
                              'Permission is required'
                            ),
                          })}
                          className="form-checkbox text-indigo-600 rounded"
                        />
                        <span
                          title={t(`user-company-role.permission.${permission.toLowerCase()}.description`)}
                          className="text-md cursor-pointer"
                        >
                          {t(`user-company-role.permission.${permission.toLowerCase()}.title`, permission)}
                        </span>
                      </div>
                    ))}
                  </div>
                </CardTableContent>
              </Card>
              <ValidationDialog
                open={open}
                onClose={setOpen}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </FullPrimaryContent>
          </ContentLayout>
        </div>
      </div>
    </>
  );
}
