import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation createSimpleUser($companyID: ID!, $input: [SimpleUserCreate!]!) {
    createSimpleUsers(companyID: $companyID, input: $input) {
      id
    }
  }
`);

export default function useCreateVirtualUser() {
  return useMutation(MUTATION, {
    refetchQueries: ['simpleCompanyUsers'],
  });
}
