import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const GOOGLE_INTEGRATIONS_QUERY = graphql(`
    query googleIntegrations($companyId: ID!) {
        googleIntegrations(companyId: $companyId) {
            id
            email
            googleCalendarIntegrations {
                calendarId
                calendarName
                enabled
                service
                syncAt
            }
        }
    }
`);

export function useGoogleIntegration(companyId: string) {
  return useQuery(GOOGLE_INTEGRATIONS_QUERY, { variables: { companyId } });
}
