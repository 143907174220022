import useFormData from '@client/module/utils/use-form-data.ts';
import useUpdateVirtualUser from '@client/page/management/appointment/employee/logic/use-update-virtual-user.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

interface VirtualUserInput {
  id: string;
  firstName: string;
  colorCode: string;
}

export default function UpdateViralUserDialog(props: { user: VirtualUserInput; onClose: () => void }) {
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const [update, error] = useUpdateVirtualUser();
  const { data, handle } = useFormData<VirtualUserInput>({
    id: props.user.id,
    firstName: props.user.firstName,
    colorCode: props.user.colorCode,
  });

  const applicationErrors = formatGraphQlError(error.error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = () => {
    update({
      variables: {
        id: data.id,
        input: {
          firstName: data.firstName,
          colorCode: data.colorCode,
        },
      },
    })
      .then(() => {
        props.onClose();
        showAlert({
          status: 'success',
          title: t('appointment.employee.update.alert.success.title', 'Success'),
          message: t('appointment.employee.update.alert.success.message', 'Successfully updated virtual user'),
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          title: t('appointment.employee.update.alert.error.title', 'Success'),
          message: e.message,
        });
      });
  };

  return (
    <Card>
      <CardHeader title={t('appointment.employee.udpate.header', 'Update employee')} onCrossButton={props.onClose} />
      <CardContent>
        <FormLayout>
          <FormItem key={data.id} title="">
            <div className="flex item-center justify-center gap-2">
              <TextInput
                type="text"
                label="name"
                value={data.firstName}
                className="grow"
                error={validationError.firstName}
                placeholder={t('user.virtual-user-create.form-input.name.placeholder', 'Name')}
                name={'firstName'}
                onChange={handle}
              />
              <div className="w-8 h-8 flex justify-center items-center overflow-hidden rounded-full">
                <input
                  type="color"
                  className="min-w-20 h-20"
                  name={'colorCode'}
                  value={data.colorCode}
                  onChange={handle}
                />
              </div>
            </div>
          </FormItem>
        </FormLayout>
      </CardContent>
      <CardFooter>
        <PrimaryButton onClick={onSubmit}>{t('common.button.update', 'Update')}</PrimaryButton>
        <SecondaryButton onClick={props.onClose}>{t('common.button.cancel', 'Update')}</SecondaryButton>
      </CardFooter>
    </Card>
  );
}
