import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import useCustomers from '@client/page/management/customer/customer-list/logic/use-customers.ts';
import Loading from '@packages/ui/loading.tsx';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import CustomerTable from '@client/page/management/customer/customer-list/component/customer-table.tsx';
import SearchInput from '@client/page/management/customer/customer-list/component/search-bar.tsx';
import { useEffect, useState } from 'react';

export interface CustomerData {
  id: string;
  customerVariants: {
    id: string;
    name: string;
    email: string;
    phone: string;
    createdAt: string | null | undefined;
  }[];
}

export default function CustomerList() {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const { data, loading, error } = useCustomers(companyId);
  const [filterData, setFilterData] = useState<CustomerData[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setFilterData(
      data.customers.map((item) => ({
        id: item.id,
        customerVariants: item.customerVariants.map((variant) => ({
          id: variant.id,
          name: variant.name,
          email: variant.email,
          phone: variant.phone,
          createdAt: variant.createdAt,
        })),
      }))
    );
  }, [data, setFilterData]);

  if (!data) {
    return <>Data is empty</>;
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <>Somethingh went wrong while fetching data</>;
  }

  const handleSearch = (searchString: string) => {
    const customer = data.customers.map((item) => ({
      id: item.id,
      customerVariants: item.customerVariants.map((variant) => ({
        id: variant.id,
        name: variant.name,
        email: variant.email,
        phone: variant.phone,
        createdAt: variant.createdAt,
      })),
    }));
    setFilterData(
      customer.filter((item) => {
        if (item.customerVariants.find((variant) => variant.name.toLowerCase().includes(searchString.toLowerCase()))) {
          return true;
        }
        if (item.customerVariants.find((variant) => variant.phone.toLowerCase().includes(searchString.toLowerCase()))) {
          return true;
        }
        if (item.customerVariants.find((variant) => variant.email.toLowerCase().includes(searchString.toLowerCase()))) {
          return true;
        }
      })
    );
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('customer.list.header', 'Customer')}>
            <SearchInput onChange={handleSearch} />
            <SecondaryButton>{t('common.button.export', 'Export')}</SecondaryButton>
          </CardHeader>
          <CardTableContent>
            <CustomerTable items={filterData} />
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
