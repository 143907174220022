import { useTranslate } from '@tolgee/react';
import { NailAppointmentConfirmationStatus } from '@packages/core/graphql/types/graphql.ts';

interface NailAppointment {
  id: string;
  startTime?: string | null | undefined;
  confirmationStatus: NailAppointmentConfirmationStatus;
  products: {
    id: string;
    appointmentId: string;
    pickedProduct: {
      id: string;
      enabled: boolean;
      title: string;
      description: string;
      images: string[];
      code: string;
      menuProduct: {
        id: string;
        configurations: {
          id: string;
          values: {
            id: string;
          }[];
        }[];
      };
      configurations: {
        id: string;
        title: string;
        value: string;
        price: number;
      }[];
    };
  }[];
}

export default function CustomerAppointmentReport(props: { appointments: (NailAppointment | null | undefined)[] }) {
  const { t } = useTranslate();
  return (
    <div className="text-lg text-gray-500 flex flex-col sm:flex-row sm:divide-x-2 bg-gray-50 py-1.5">
      <div className="flex items-center sm:justify-center gap-1 px-3 py-1.5 sm:px-8 sm:basis-1/2">
        <span>{t('customer.customer-detail.appointment.count', 'Quantity:')}</span>
        <span>{props.appointments.length}</span>
      </div>
      <div className="flex items-center sm:justify-center gap-1 px-3 py-1.5 sm:px-8 sm:basis-1/2">
        <span>{t('customer.customer-detail.appointment.cancelled', 'Cancelled:')}</span>
        <span>
          {
            props.appointments.filter((item) => item?.confirmationStatus == NailAppointmentConfirmationStatus.Cancelled)
              .length
          }
        </span>
      </div>
    </div>
  );
}
