import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import { Link } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CustomerData } from '@client/page/management/customer/customer-list/customer-list.tsx';

export default function CustomerTable(props: { loading?: boolean; items: CustomerData[] | undefined }) {
  const { items } = props;
  const { t } = useTranslate();

  if (!items) {
    return <div>Empty data</div>;
  }

  return (
    <>
      <Table loading={props.loading} data={items} build={(item) => <TableGroupRow key={item.id} customer={item} />}>
        <TableHeaderColumn>{t('customer.list.name', 'UserName')}</TableHeaderColumn>
        <TableHeaderColumn>{t('customer.list.phone', 'Phone')}</TableHeaderColumn>
        <TableHeaderColumn>{t('customer.list.email', 'Email')}</TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { customer: CustomerData }) {
  const { customer } = props;

  return (
    <>
      <TableRow key={customer.id} className="hover:bg-gray-100">
        <TableRowColumn>
          <Link to={customer.id}>
            <p className="w-full">{customer.customerVariants[0].name}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={customer.id}>
            <p className="max-w-24 sm:max-w-32 truncate">{customer.customerVariants[0].phone}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={customer.id}>
            <p className="max-w-28 sm:max-w-52 truncate">{customer.customerVariants[0].email}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn className="space-x-4">
          <div className="flex items-center justify-end ">
            <ChevronRightIcon className="w-7 fill-gray-400" />
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
