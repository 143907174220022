import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from 'ui/src/card/card-content';
import { useEffect } from 'react';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardFooter from 'ui/src/card/card-footer';
import PrimaryButton from 'ui/src/button/primary-button';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useTranslate } from '@tolgee/react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { captureException } from '@sentry/browser';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { Controller, useForm } from 'react-hook-form';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { ApolloError } from '@apollo/client';
import { useUpdateCompany } from './logic/use-update-company-profile';

interface CompanyUpdateInput {
  instagramLink: string | null;
  facebookLink: string | null;
  googleLink: string | null;
  tiktokLink: string | null;
}

export default function CompanySocialSetting() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const data = useCompanyService();
  const { showAlert } = useNotificationCenter();

  const [updateCompanyProfile] = useUpdateCompany();


  const form = useForm<CompanyUpdateInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, setError } = form;

  useEffect(() => {
    form.reset({
      instagramLink: data.company?.instagramLink ?? '',
      facebookLink: data.company?.facebookLink ?? '',
      googleLink: data.company?.googleLink ?? '',
      tiktokLink: data.company?.tiktokLink ?? '',
    });
  }, [
    data.company?.instagramLink,
    data.company?.facebookLink,
    data.company?.googleLink,
    data.company?.tiktokLink,
    form,
  ]);

  const onSubmit = async (updateData: CompanyUpdateInput) => {
    try {
      const result = await updateCompanyProfile({
        variables: {
          companyID: companyID,
          input: {
            name: data.company?.name ?? '',
            instagramLink: updateData.instagramLink,
            facebookLink: updateData.facebookLink,
            googleLink: updateData.googleLink,
            tiktokLink: updateData.tiktokLink,
          },
        },
      });
      if (result.data) {
        showAlert({
          status: 'success',
          title: t('company.setting.social.update.alert.title', 'Update Success'),
          message: t('company.setting.social.update.alert.message', 'Update social successfully'),
        });
        location.reload();
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof CompanyUpdateInput,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <Card>
      <CardHeader title={'Social setting'} />

      <CardContent>
        <form className="space-y-6"
              onSubmit={() => {
                handleSubmit(onSubmit);
              }}>
          {/*Instagram*/}
          <FormItem className="max-w-xl" title={t('company.social-setting.form-input.instagram.title', 'Instagram')}>
            <Controller name={'instagramLink'} control={form.control} render={({ field }) => (
              <TextInput type="text"
                         label="instagram"
                         value={field.value ?? ''}
                         name="instagram"
                         placeholder={t('company.social-setting.form-input.instagram.placeholder', 'https://')}
                         onChange={field.onChange} />
            )} />
          </FormItem>

          {/*Facebook*/}
          <FormItem className="max-w-xl" title={t('company.social-setting.form-input.facebook.title', 'Facebook')}>
            <Controller name={'facebookLink'} control={form.control} render={({ field }) => (
              <TextInput type="text"
                         label="facebook"
                         value={field.value ?? ''}
                         name="facebook"
                         placeholder={t('company.social-setting.form-input.facebook.placeholder', 'https://')}
                         onChange={field.onChange} />
            )} />
          </FormItem>

          {/*Google*/}
          <FormItem className="max-w-xl" title={t('company.social-setting.form-input.google.title', 'Google')}>
            <Controller name={'googleLink'} control={form.control} render={({ field }) => (
              <TextInput type="text"
                         label="google"
                         value={field.value ?? ''}
                         name="google"
                         placeholder={t('company.social-setting.form-input.google.placeholder', 'https://')}
                         onChange={field.onChange} />
            )} />
          </FormItem>

          {/*Tiktok*/}
          <FormItem className="max-w-xl" title={t('company.social-setting.form-input.tiktok.title', 'Tiktok')}>
            <Controller name={'tiktokLink'} control={form.control} render={({ field }) => (
              <TextInput type="text"
                         label="tiktok"
                         value={field.value ?? ''}
                         name="tiktok"
                         placeholder={t('company.social-setting.form-input.tiktok.placeholder', 'https://')}
                         onChange={field.onChange} />
            )} />
          </FormItem>

        </form>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-32">
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              handleSubmit(onSubmit)().catch(captureException);
            }}
          >
            {t('common.update')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
