import { useCallback, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { uploadImage } from '@packages/core/uploading/upload-image.ts';
import AlertError from 'ui/src/alert/alert-error.tsx';
import UploadArea from '@packages/core/uploading/upload-area.tsx';
import Spinner from 'ui/src/spinner.tsx';
import { XMarkIcon } from '@heroicons/react/16/solid';
import getImageLink from 'src/image-source/get-image-link';
import { useTranslate } from '@tolgee/react';
import { useNotificationCenter } from 'ui/src/notification/notification-center.ts';

export default function ImageInput(props: {
  client: ApolloClient<NormalizedCacheObject>;
  images: string[];
  onChange: (images: string[]) => void;
  multiple?: boolean;
  maxFiles?: number;
  maxSizes?: number;
}) {
  const { client, images, onChange, multiple, maxSizes, maxFiles } = props;
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const upload = useCallback(
    (uploadingImages: File[]) => {
      if (uploadingImages.length === 0) return;

      if (maxFiles && uploadingImages.length > maxFiles - images.length) {
        showAlert({
          status: 'error',
          title: t('hubpage.image.alert.file-limit.title', 'File limited'),
          message: t('hubpage.image.alert.file-limit.message', 'File count limit is {quantity}', {
            quantity: maxFiles,
          }),
        });
        return;
      }

      if (maxFiles && images.length >= maxFiles) {
        showAlert({
          status: 'error',
          title: t('hubpage.image.alert.file-limit.title', 'File limited'),
          message: t('hubpage.image.alert.file-limit.message', 'File count limit is {quantity}', {
            quantity: maxFiles,
          }),
        });
        return;
      }

      if (maxSizes && uploadingImages.find((item) => item.size > maxSizes * 1024 * 1024)) {
        showAlert({
          status: 'error',
          title: t('hubpage.image.alert.file-size.title', 'File Size Limited'),
          message: t('hubpage.image.alert.file-size.message', 'File size limit is {quantity}', {
            quantity: maxSizes,
          }),
        });
        return;
      }

      setUploading(true);

      Promise.all(uploadingImages.map((file) => uploadImage(client, file)))
        .then((newImages) => {
          if (props.multiple) {
            onChange(images.concat(newImages.flatMap((e) => (e.data ? [e.data.preSignImageUpload.originPath] : []))));
          } else {
            onChange(
              newImages[0].data?.preSignImageUpload.originPath ? [newImages[0].data.preSignImageUpload.originPath] : []
            );
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            setError(e);
          }
        })
        .finally(() => {
          setUploading(false);
        });
    },
    [client, props.multiple, onChange, images, maxFiles, maxSizes, showAlert, t]
  );

  return (
    <div className="pb-8 border-b border-gray-900/10">
      <div className="">
        {error && <AlertError title={error.name} message={error.message} />}
        <div className="flex items-center gap-4 flex-wrap">
          {uploading ? (
            <div className="w-80 h-40 flex items-center justify-center">
              <div className="flex-col items-center justify-center justify-self-center content-center">
                <Spinner />
                <p className="-ml-5 mt-2 text-gray-400">{t('common.component.photo.uploading')}</p>
              </div>
            </div>
          ) : (
            <UploadArea onUpload={upload} multiple={multiple} maxFiles={maxFiles} maxSizes={maxSizes} />
          )}

          {props.images.map((image) => {
            return (
              <div key={image} className="flex">
                <img
                  data-testid={image}
                  alt="preview"
                  src={getImageLink(image)}
                  className="w-40 h-40 mr-2 object-cover rounded-lg border border-gray-900/25"
                />
                <div
                  onClick={() => {
                    onChange(images.filter((i) => i !== image));
                  }}
                  className="absolute w-6 h-6 p-1 text-white font-bold bg-red-500 rounded-3xl cursor-pointer"
                >
                  <XMarkIcon />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
