import useFormData from '@client/module/utils/use-form-data';
import { BlockerFunction, useBlocker, useNavigate } from 'react-router-dom';
import PrimaryButton from 'ui/src/button/primary-button';
import SecondaryButton from 'ui/src/button/secondary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import ToggleInput from 'ui/src/form/input/toggle-input';
import { useCallback, useEffect, useState } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import { useTranslate } from '@tolgee/react';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { Helmet } from 'react-helmet-async';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context.ts';
import DestroyButton from 'ui/src/button/destroy-button.tsx';
import TableReservationAdvancedSetting
  from '@client/page/management/table-reservation/setting/component/table-reservation-advanced-setting.tsx';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import PhoneInput from 'react-phone-number-input/min';
import useUpdateTableReservationSetting from './logic/use-update-table-reservation-setting.ts';
import useTableReservationSetting from './logic/use-table-reservation-setting.ts';
import 'react-phone-number-input/style.css'



interface TableBookingSetting {
  status: boolean;
  pause: boolean;
  autoConfirm: boolean;
  seatLimit: number;
  ignoreStatus: boolean;
  notificationMails: string;
  phone: string;
  reservationLeadMinutes: number;
  reservationTraitMinutes: number;
  hourBlocking: number;
  defaultBookingTimeDuration: number;
  reminderTime: number;
  flexDuration: number[];
  flexEnabled: boolean;
}

export default function TableReservationSettingGeneral() {
  const navigate = useNavigate();
  const notificationCenter = useNotificationCenter();
  const dialogManager = useModalManagerContext();
  const dialog = useDialog();

  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => hasBeenChanged && currentLocation.pathname !== nextLocation.pathname,
    [hasBeenChanged],
  );

  const blocker = useBlocker(shouldBlock);

  const { t } = useTranslate();
  const { auth } = useAuth();

  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';

  const { data, refetch } = useTableReservationSetting(branchId);
  const [update] = useUpdateTableReservationSetting();
  const {
    data: form,
    setData,
    handle,
    manualHandle,
  } = useFormData<TableBookingSetting>({
    status: false,
    pause: false,
    autoConfirm: false,
    ignoreStatus: false,
    notificationMails: '',
    phone: '',
    reservationLeadMinutes: 0,
    reservationTraitMinutes: 0,
    hourBlocking: 0,
    defaultBookingTimeDuration: 0,
    seatLimit: 0,
    reminderTime: 60,
    flexDuration: [],
    flexEnabled: false,
  });

  useEffect(() => {
    setData({
      status: data?.tableReservationConfiguration.status ?? false,
      pause: data?.tableReservationConfiguration.pause ?? false,
      autoConfirm: data?.tableReservationConfiguration.autoConfirm ?? false,
      ignoreStatus: data?.tableReservationConfiguration.ignoreStatus ?? false,
      phone: data?.tableReservationConfiguration.phone ?? '',
      notificationMails: data?.tableReservationConfiguration.notificationMails.join('\n') ?? '',
      reservationLeadMinutes: data?.tableReservationConfiguration.reservationLeadMinutes ?? 0,
      reservationTraitMinutes: data?.tableReservationConfiguration.reservationTraitMinutes ?? 0,
      hourBlocking: data?.tableReservationConfiguration.hourBlocking ?? 0,
      defaultBookingTimeDuration: data?.tableReservationConfiguration.defaultBookingTimeDuration ?? 0,
      seatLimit: data?.tableReservationConfiguration.seatLimit ?? 0,
      reminderTime: data?.tableReservationConfiguration.reminderTime ?? 60,
      flexDuration: data?.tableReservationConfiguration.flexDuration ?? [],
      flexEnabled: data?.tableReservationConfiguration.flexEnabled ?? false,
    });
  }, [data, setData]);

  const reset = useCallback(() => {
    setData({
      status: data?.tableReservationConfiguration.status ?? false,
      pause: data?.tableReservationConfiguration.pause ?? false,
      autoConfirm: data?.tableReservationConfiguration.autoConfirm ?? false,
      ignoreStatus: data?.tableReservationConfiguration.ignoreStatus ?? false,
      phone: data?.tableReservationConfiguration.phone ?? '',
      notificationMails: data?.tableReservationConfiguration.notificationMails.join('\n') ?? '',
      reservationLeadMinutes: data?.tableReservationConfiguration.reservationLeadMinutes ?? 0,
      reservationTraitMinutes: data?.tableReservationConfiguration.reservationTraitMinutes ?? 0,
      hourBlocking: data?.tableReservationConfiguration.hourBlocking ?? 0,
      defaultBookingTimeDuration: data?.tableReservationConfiguration.defaultBookingTimeDuration ?? 0,
      seatLimit: data?.tableReservationConfiguration.seatLimit ?? 0,
      reminderTime: data?.tableReservationConfiguration.reminderTime ?? 60,
      flexDuration: data?.tableReservationConfiguration.flexDuration ?? [],
      flexEnabled: data?.tableReservationConfiguration.flexEnabled ?? false,
    });

    blocker.reset?.();
  }, [data, setData, blocker]);

  // Detect change between form and data
  useEffect(() => {
    if (data) {
      if (
        form.status !== data.tableReservationConfiguration.status ||
        form.pause !== data.tableReservationConfiguration.pause ||
        form.autoConfirm !== data.tableReservationConfiguration.autoConfirm ||
        form.ignoreStatus !== data.tableReservationConfiguration.ignoreStatus ||
        form.phone !== data.tableReservationConfiguration.phone ||
        form.notificationMails !== data.tableReservationConfiguration.notificationMails.join('\n') ||
        form.reservationLeadMinutes !== data.tableReservationConfiguration.reservationLeadMinutes ||
        form.reservationTraitMinutes !== data.tableReservationConfiguration.reservationTraitMinutes ||
        form.hourBlocking !== data.tableReservationConfiguration.hourBlocking ||
        form.defaultBookingTimeDuration !== data.tableReservationConfiguration.defaultBookingTimeDuration ||
        form.seatLimit !== data.tableReservationConfiguration.seatLimit ||
        form.reminderTime !== data.tableReservationConfiguration.reminderTime ||
        form.flexDuration !== data.tableReservationConfiguration.flexDuration ||
        form.flexEnabled !== data.tableReservationConfiguration.flexEnabled
      ) {
        setHasBeenChanged(true);
      } else {
        setHasBeenChanged(false);
      }
    }
  }, [data, form]);

  const handleSubmit = () => {
    if (!data) {
      notificationCenter.showAlert({
        title: t('alert.title.success'),
        message: t('reservation.setting.alert.message.error'),
        status: 'error',
      });
      return;
    }
    dialog.confirmAlert({
      title: t('reservation.setting.alert.confirm.title'),
      message: t('reservation.setting.alert.confirm.message'),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        await update({
          variables: {
            id: data.tableReservationConfiguration.id,
            input: {
              status: form.status,
              pause: form.pause,
              autoConfirm: form.autoConfirm,
              ignoreStatus: form.ignoreStatus,
              phone: form.phone,
              notificationMails: form.notificationMails.split('\n'),
              reservationLeadMinutes: +form.reservationLeadMinutes,
              reservationTraitMinutes: +form.reservationTraitMinutes,
              hourBlocking: +form.hourBlocking,
              defaultBookingTimeDuration: +form.defaultBookingTimeDuration,
              seatLimit: +form.seatLimit,
              reminderTime: +form.reminderTime,
              flexDuration: form.flexDuration,
              flexEnabled: form.flexEnabled,
            },
          },
        });

        await refetch();
        blocker.reset?.();

        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('reservation.setting.alert.message.success'),
          status: 'success',
        });
      },
    });
  };

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={t('reservation.setting.update-dialog.title')} />

          <CardContent>
            <p className="text-lg text-gray-600">{t('reservation.setting.update-dialog.content')}</p>
          </CardContent>

          <CardFooter>
            <div className="w-full flex justify-between">
              <PrimaryButton onClick={handleSubmit}>{t('common.button.update')}</PrimaryButton>

              <div className="flex gap-2">
                <DestroyButton
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  {t('common.button.discard')}
                </DestroyButton>
                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common.button.cancel')}
                </SecondaryButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state === 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });

  return (
    <ContentLayout>
      <Helmet>
        <title> {t('reservation.setting.helmet')}</title>
        <meta name="description" content="Table Booking Setting" />
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('table-reservation-setting.general.header', 'General')}>
          </CardHeader>
          <CardContent>
            <FormLayout>
              {/* Status */}
              <FormItem className="max-w-md pb-4 border-b" title={t('common.toggle.active')}>
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.active.description')}
                </p>
                <ToggleInput
                  value={form.status}
                  onChange={(newValue) => {
                    manualHandle('status', newValue);
                  }}
                />
              </FormItem>

              {/* Pause */}
              <FormItem className="max-w-md pb-4 border-b" title={t('common.toggle.pause')}>
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.pause.description')}
                </p>
                <ToggleInput
                  value={form.pause}
                  onChange={(newValue) => {
                    manualHandle('pause', newValue);
                  }}
                />
              </FormItem>

              {/* Auto confirm */}
              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('reservation.setting.form-input.auto-confirm.title')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.auto-confirm.description')}
                </p>
                <ToggleInput
                  value={form.autoConfirm}
                  onChange={(newValue) => {
                    manualHandle('autoConfirm', newValue);
                  }}
                />
              </FormItem>

              {/* seat limit */}
              <FormItem className="max-w-md pb-4 border-b" title={t('reservation.setting.form-input.seat-limit.title')}>
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.seat-limit.description')}
                </p>
                <TextInput
                  type={'number'}
                  label={'guests-limit'}
                  value={form.seatLimit}
                  name={'seatLimit'}
                  placeholder={t('reservation.setting.form-input.seat-limit.placeholder')}
                  onChange={handle}
                />
              </FormItem>

              {/* hourBlocking */}
              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('reservation.setting.form-input.hour-blocking.title')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.hour-blocking.description')}
                </p>
                <TextInput
                  type={'number'}
                  label={'hour-blocking'}
                  value={form.hourBlocking}
                  name={'hourBlocking'}
                  placeholder={t('reservation.setting.form-input.hour-blocking.placeholder')}
                  onChange={handle}
                />
              </FormItem>

              {/* BookingTimeDuration */}
              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('reservation.setting.form-input.booking-time-duration.title')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.booking-time-duration.description')}
                </p>
                <TextInput
                  type={'number'}
                  label={'booking-time-duration'}
                  value={form.defaultBookingTimeDuration}
                  name={'defaultBookingTimeDuration'}
                  placeholder={t('reservation.setting.form-input.booking-time-duration.placeholder')}
                  onChange={handle}
                />
              </FormItem>

              {/* reservationLeadMinutes */}
              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('reservation.setting.form-input.lead-minute.title')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.lead-minute.description')}
                </p>
                <TextInput
                  type={'number'}
                  label={'lead-minute'}
                  value={form.reservationLeadMinutes}
                  name={'reservationLeadMinutes'}
                  placeholder={t('reservation.setting.form-input.lead-minute.placeholder')}
                  onChange={handle}
                />
              </FormItem>

              {/* reservationTraitMinutes */}
              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('reservation.setting.form-input.trait-minute.title')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.trait-minute.description')}
                </p>
                <TextInput
                  type={'number'}
                  label={'trait-minute'}
                  value={form.reservationTraitMinutes}
                  name={'reservationTraitMinutes'}
                  placeholder={t('reservation.setting.form-input.trait-minute.placeholder')}
                  onChange={handle}
                />
              </FormItem>

              {/* Phone */}
              <FormItem className="max-w-md pb-4 border-b" title={t('reservation.setting.form-input.phone.title')}>
                <p className="text-sm text-gray-500 italic my-4">
                  {t('reservation.setting.form-input.phone.description')}
                </p>
                {/*<TextInput*/}
                {/*  type={'text'}*/}
                {/*  label={'phone'}*/}
                {/*  value={form.phone}*/}
                {/*  name={'phone'}*/}
                {/*  placeholder={t('reservation.setting.form-input.phone.placeholder')}*/}
                {/*  onChange={handle}*/}
                {/*/>*/}

                <PhoneInput
                  className="pl-2.5 pt-1.5 pb-1.5 pr-2 h-auto block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultCountry={'DE'}
                  placeholder={t(
                    'reservation.setting.form-input.phone.placeholder',
                    'Enter phone number',
                  )}
                  value={form.phone}
                  onChange={
                    (value) => {
                      manualHandle('phone', value?.toString() ?? '');
                    }
                  } />
              </FormItem>
            </FormLayout>
          </CardContent>
          <CardFooter>
            <PrimaryButton onClick={handleSubmit}>{t('common.button.update')}</PrimaryButton>
            <SecondaryButton
              onClick={() => {
                navigate('..');
              }}
            >
              {t('common.button.cancel')}
            </SecondaryButton>
          </CardFooter>
        </Card>
        <AdditionalSettings branchID={branchId} />

        {auth?.role.includes('SUPER_ADMIN') && <TableReservationAdvancedSetting />}
      </PrimaryContent>
    </ContentLayout>
  );
}

const AdditionalSettings = (props: { branchID: string }) => {
  const { branchID } = props;
  const companyID = useCompanyId();
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const copyLinkHandle = () => {
    void navigator.clipboard
      .writeText(
        `${import.meta.env.VITE_STOREFRONT_HOST as string}/table-reservation?companyID=${companyID}&branchID=${branchID}`,
      )
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('reservation.setting.additional-setting.content.reservation-link.alert.message.success'),
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('reservation.setting.additional-setting.content.reservation-link.alert.message.error'),
        });
      });
  };

  return (
    <Card>
      <CardHeader title={t('reservation.setting.additional-setting.title')} />
      <CardContent>
        <FormLayout>
          <FormItem title={t('reservation.setting.additional-setting.content.reservation-link.title')}>
            <p className="pb-4 text-gray-600 text-sm">
              {t('reservation.setting.additional-setting.content.reservation-link.description')}
            </p>

            <SecondaryButton
              onClick={() => {
                copyLinkHandle();
              }}
            >
              {t('common.button.copy-link')}
            </SecondaryButton>
          </FormItem>
        </FormLayout>
      </CardContent>
    </Card>
  );
};
