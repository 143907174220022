import Card from '@packages/ui/card/card.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useCreateMenu } from '@client/page/management/menu/menu-create/logic/use-create-menu.ts';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useNavigate } from 'react-router-dom';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { MenuSource } from '@client/graphql/types/graphql';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/browser';
import { useTranslate } from '@tolgee/react';
import { ApolloError } from '@apollo/client';

interface FormValues {
  title: string;
  menuSource: MenuSource;
}

export default function ServiceCreate() {
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const [create, { loading }] = useCreateMenu();

  const form = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, handleSubmit, setError } = form;

  const onSubmit = async (data: FormValues) => {
    try {
      const result = await create({
        variables: {
          companyId: companyId,
          input: {
            title: data.title.trim(),
            menuSource: MenuSource.NailAppointment,
          },
        },
      });
      if (result.data) {
        showAlert({
          status: 'success',
          title: t('service.service-create.notification.success.title', 'Service created'),
          message: t('service.service-create.notification.success.message', 'Service has been created successfully'),
        });
        navigate('..');
      } else {
        showAlert({
          status: 'error',
          title: t('service.service-create.notification.error.title', 'Service menu creation failed'),
          message: t('service.service-create.notification.error.message', 'Failed to create service menu'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('service.service-create.header', 'Service menu create')} withBackButton={true} />
          <CardContent>
            <form
              className="space-y-6"
              onSubmit={() => {
                handleSubmit(onSubmit);
              }}
            >
              <FormItem title={t('service.service-create.form-input.menu-title.title', 'Title')} className="max-w-md">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                  type="text"
                  {...register('title', {
                    required: t(
                      'service.service-create.form-input.menu-title.validation.required',
                      'Service menu title is required'
                    ),
                    minLength: {
                      value: 3,
                      message: t(
                        'service.service-create.form-input.menu-title.validation.min-length',
                        'Service menu title must be at least 3 characters'
                      ),
                    },
                    maxLength: {
                      value: 50,
                      message: t(
                        'service.service-create.form-input.menu-title.validation.max-length',
                        'Service menu title must be at most 50 characters'
                      ),
                    },
                    pattern: {
                      value: /^\S.*\S$/,
                      message: t(
                        'service.service-create.form-input.menu-title.validation.no-space',
                        'Service menu title must not start or end with whitespace'
                      ),
                    },
                  })}
                />
                {form.formState.errors.title && (
                  <p className="text-red-500 text-xs pt-1">{form.formState.errors.title.message}</p>
                )}
              </FormItem>
            </form>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center">
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32"
                onClick={() => {
                  handleSubmit(onSubmit)().catch(captureException);
                }}
                loading={loading}
              >
                {t('common.button.create', 'Create')}
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
