import useGetVirtualUsers from '@client/page/management/appointment/create/logic/use-get-virtual-users.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import Loading from '@packages/ui/loading.tsx';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import AppointmentEmployeeTable from '@client/page/management/appointment/employee/component/appointment-employee-table.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CreateVirtualUserDialog from '@client/page/management/appointment/employee/component/create-virtual-user-dialog.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';

export default function AppointmentEmployee() {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const { data, loading } = useGetVirtualUsers(companyId);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <>Data is empty</>;
  }

  const createVirtualUserDialog = () => {
    dialogManager.showDialog((onClose) => <CreateVirtualUserDialog onClose={onClose} />);
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('appoitment.employee.header', 'Employees')}>
            <SecondaryButton onClick={createVirtualUserDialog} data-testid="list-create-button">
              {t('appoitment.employee.button.create-virtual-user', 'Create')}
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <AppointmentEmployeeTable employees={data.simpleCompanyUsers} />
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
