import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';


const DELETE_OPENING_TIMES_MUTATION = graphql(`
    mutation deleteOpeningTimes($branchId: ID!, $service: OpeningTimeServiceType!) {
        deleteOpeningTimes(branchID: $branchId,service: $service)
    }
`);

export function useDeleteManyTimes(
) {
  return useMutation(DELETE_OPENING_TIMES_MUTATION, {
    refetchQueries: ['OpeningTimes'],
  });
}