import { useCallback } from 'react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

function mbToBytes(mb: number | undefined) {
  if (!mb) {
    return;
  }
  return mb * 1024 * 1024; // 1 MB = 1024 * 1024 bytes
}

export default function UploadArea(props: {
  onUpload: (files: File[]) => void;
  multiple?: boolean;
  maxFiles?: number;
  maxSizes?: number;
}) {
  const { multiple, maxFiles, maxSizes } = props;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      props.onUpload(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    maxFiles,
    maxSize: mbToBytes(maxSizes),
  });
  const { t } = useTranslate();
  return (
    <div
      className="w-40 h-40 flex justify-center rounded-lg border border-dashed border-gray-900/25 py-10"
      {...getRootProps()}
    >
      <div className="">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
        <div className="w-full mt-4 flex justify-center text-sm leading-6 text-gray-600">
          <label
            htmlFor="file-upload"
            className={classNames(
              'relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 ',
              'focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2',
              'hover:text-indigo-500'
            )}
          >
            <span>{isDragActive ? t('common.component.photo.drag') : t('common.component.photo.drop-file-here')}</span>
            <input {...getInputProps()}></input>
          </label>
          {/*<p className="pl-1">or drag and drop</p>*/}
        </div>
        <p className="text-xs leading-5 text-gray-600">{t('common.component.photo.type')}</p>
      </div>
    </div>
  );
}
