import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation updateSimpleUser($id: ID!, $input: SimpleUserUpdate!) {
    updateSimpleUser(id: $id, input: $input) {
      id
    }
  }
`);

export default function useUpdateVirtualUser() {
  return useMutation(MUTATION, {
    refetchQueries: ['simpleCompanyUsers'],
  });
}
