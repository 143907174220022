import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Card from '@packages/ui/card/card.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useTranslate } from '@tolgee/react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useGetVirtualUsers from '@client/page/management/appointment/create/logic/use-get-virtual-users.ts';
import useUpdateAppointment from '@client/page/management/appointment/detail/logic/use-update-appointment.ts';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '@packages/ui/spinner.tsx';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import EmployeeDot from '@client/page/management/appointment/employee/component/employee-dot.tsx';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import classNames from 'classnames';

interface UserCompanyRole {
  id: string;
  user: {
    id: string;
    firstName: string;
    colorCode: string;
  };
}

export default function EmployeePicker(props: {
  appointmentId: string;
  userId: string | undefined | null;
  userFirstName?: string;
}) {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const { data, loading } = useGetVirtualUsers(companyId);
  const [update] = useUpdateAppointment();

  const [userData, setUserData] = useState<UserCompanyRole[]>([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!data) {
      return;
    }

    setUserData(
      data.simpleCompanyUsers.map((item) => ({
        id: item.id,
        user: {
          id: item.user.id,
          firstName: item.user.firstName,
          colorCode: item.user.colorCode,
        },
      }))
    );
  }, [data, props.userFirstName]);

  const handleSearchBar = useCallback(
    (searchString: string) => {
      if (!data) {
        return;
      }

      setSearchString(searchString);

      setUserData(
        data.simpleCompanyUsers.filter((user) => {
          if (user.user.firstName.toLowerCase().includes(searchString.toLowerCase().trim())) {
            return true;
          }
          return false;
        })
      );
    },
    [data, setUserData]
  );

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <>Empty user data</>;
  }

  const handleClick = (userId?: string | null) => {
    if (userId == props.userId && props.userId == '') {
      return;
    }

    let userIdParam = userId;
    if (userId == props.userId) {
      userIdParam = '';
    }

    update({
      variables: {
        id: props.appointmentId,
        userId: userIdParam,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          message: t(
            'appointment.update.employee.alert.success.message',
            'Your appointment has been updated successfully.'
          ),
          title: t('appointment.update.employee.alert.success.title', 'Success'),
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          message: e.message,
          title: t('appointment.update.employee.alert.success.title', 'Error'),
        });
      });
  };

  const currentUserName =
    props.userFirstName ?? data.simpleCompanyUsers.find((user) => user.user.id == props.userId)?.user.firstName ?? '';
  const currentColorCode =
    data.simpleCompanyUsers.find((user) => user.user.id == props.userId)?.user.colorCode ?? '#D1D5DB';

  return (
    <Card>
      <CardHeader title={'Employees'} />

      <CardTableContent>
        <div className="text-gray-700 flex items-center px-2 py-1 gap-2 truncate border-b border-1">
          <MagnifyingGlassIcon className="w-6 h-6 ml-2 text-gray-400" />
          <input
            type="search"
            value={searchString}
            name="search"
            className="w-full border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-400"
            placeholder={t('appontment.appointment-create.user.search.placeholder', 'Type to find Employees')}
            onChange={(e) => {
              handleSearchBar(e.target.value);
            }}
          />
        </div>
        <div className="divide-y">
          {props.userFirstName && (
            <>
              <div key={props.userId} className="flex items-center px-5 py-4 gap-4 truncate">
                <CheckBoxInput
                  value={true}
                  onChange={() => {
                    handleClick(props.userId);
                  }}
                />
                <div className="flex items-center gap-1">
                  <EmployeeDot colorCode={currentColorCode} />
                  <span className={classNames(!props.userId && 'text-gray-400')}>{currentUserName}</span>
                </div>
              </div>
            </>
          )}
          {userData
            .filter((user) => user.user.id !== props.userId)
            .slice(0, 10)
            .map((user) => {
              const colorCode = user.user.colorCode;
              return (
                <div key={user.user.id} className="flex items-center px-5 py-4 gap-4 truncate">
                  <CheckBoxInput
                    value={user.user.id == props.userId}
                    onChange={() => {
                      handleClick(user.user.id);
                    }}
                  />
                  <div className="flex items-center gap-1">
                    <EmployeeDot colorCode={colorCode} />
                    {user.user.firstName}
                  </div>
                </div>
              );
            })}
        </div>
      </CardTableContent>
    </Card>
  );
}
