import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation deleteGoogleIntegration($id: ID!) {
    deleteGoogleIntegration(id: $id)
  }
`);

export function useGoogleIntegrationDelete() {
  return useMutation(MUTATION);
}
