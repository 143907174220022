import { ReactNode } from 'react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

interface Action {
  href: string;
  name: string;
}

export default function DescriptionItem(props: {
  title: string;
  children?: ReactNode;
  action?: Action[];
}) {
  return (
    <div className="py-2 sm:col-span-1 sm:px-0 flex flex-col md:flex-row   items-start sm:gap-10">
      <div>
        <dt className="text-sm  font-medium leading-6 text-gray-400">{props.title}</dt>
        <dd className="text-sm leading-6 text-gray-700">{props.children}</dd>
      </div>
      <div className="flex gap-3">
        {props.action?.map((action: Action, index) => (
          <div className="mt-2 md:mt-0 md:w-auto" key={index}>
            <a href={action.href}>
              <SecondaryButton>{action.name}</SecondaryButton>
            </a>
          </div>
        ))
        }
      </div>
    </div>
  );
}
