import Card from 'ui/src/card/card';
import CardTableContent from 'ui/src/card/card-content-table';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import { useCompanyId } from 'core/src/company/focused-company-context';
import { useEffect } from 'react';
import moment from 'moment';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import SecondaryButton from 'ui/src/button/secondary-button';
import UserNotificationList
  from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-list.tsx';
import { PushNotificationToggle } from '@client/module/push-notification/push-notification-toggle.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import Pagination from '@packages/core/utils/pagination/pagination.tsx';
import usePagination from '@packages/core/utils/pagination/use-pagination.ts';
import { useTranslate } from '@tolgee/react';
import UserNotificationTable from './component/user-notification-table';
import useGetUserNotification from './logic/use-get-user-notifications';

export default function UserNotificationListPage() {
  const companyID = useCompanyId();
  const { data, refetch, error } = useGetUserNotification(companyID);
  const { t } = useTranslate();

  useEffect(() => {
    localStorage.setItem('latestAccessTime', moment().toISOString());
  }, []);

  // Filter notifications to include only those created in the last 7 days
  const filteredNotifications = data?.userNotifications.filter(notification =>
    moment(parseInt(notification.createdAt)).isAfter(moment().subtract(7, 'days')),
  );

  // Set up pagination with a limit of 10 items per page
  const itemsPerPage = 10;
  const paginationInfo = usePagination(filteredNotifications?.length ?? 0, { limit: itemsPerPage });

  // Calculate the current items to display based on pagination
  const paginatedNotifications = filteredNotifications?.slice(
    paginationInfo.info.current * itemsPerPage,
    (paginationInfo.info.current + 1) * itemsPerPage,
  ) ?? [];

  return (
    <ContentLayout>
      <FullPrimaryContent>
        <ApplicationErrorView error={error} />
        <Card>
          <CardHeader title={t('user-notification.list-page.header', 'Benutzerbenachrichtigung')} withBackButton={true}
                      backTarget={'/'}>
            <PushNotificationToggle />
            <SecondaryButton
              onClick={() => {
                refetch().catch((e) => void e);
              }}
            >
              <ArrowPathIcon className="w-4" />
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <div className="max-sm:hidden">
              <UserNotificationTable items={paginatedNotifications} />
            </div>
            <div className="sm:hidden">
              <UserNotificationList items={paginatedNotifications} />
            </div>
          </CardTableContent>
          <CardContent>
            <Pagination info={paginationInfo.info} setCurrent={paginationInfo.updateCurrent} />
          </CardContent>
        </Card>
      </FullPrimaryContent>
    </ContentLayout>
  );
}
