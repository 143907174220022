import classNames from 'classnames';

const EmployeeDot = (props: { colorCode: string }) => {
  const { colorCode } = props;

  return (
    <div
      style={{
        color: colorCode, // text-gray-400
      }}
      className={classNames('h-4 w-4 flex-none rounded-full p-1')}
    >
      <div className="h-2 w-2 rounded-full bg-current" />
    </div>
  );
};

export default EmployeeDot;
