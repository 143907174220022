import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';

export function useCompanyHubpageLink(): string {
  const companyId = useCompanyId();
  const { data } = useCompanySettings(companyId);
  const branchId = data?.company?.branch?.[0]?.id ?? '';
  const subDomain = data?.company?.hubPage?.subDomain ?? '';

  const hubHost: string = import.meta.env.VITE_HUB_HOST as string;

  // TODO: Make language base on preferred customer language in companySettings
  return `https://${subDomain}.${hubHost}/de/${branchId}`;
}
