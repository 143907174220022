import OnlineOrderAction from '@client/page/management/online-order/detail/component/online-order-action.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useGetOnlineOrder from '@client/page/management/online-order/detail/logic/use-get-online-order.ts';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderFragment } from '@client/graphql/online-order-fragment.ts';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import {
  ChevronDownIcon,
  QuestionMarkCircleIcon,
  BoltIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid';
import { OnlineOrderState } from '@client/graphql/types/graphql.ts';
import { ButtonSize } from '@packages/ui/button/button-size.ts';
import { ReactNode } from 'react';
import { useTranslate } from '@tolgee/react';

function OnlineOrderDialog(props: { orderId: string; onClose?: () => void }) {
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';
  const { data: fetchedData } = useGetOnlineOrder(props.orderId, branchID);

  const fetchedOrder = getFragmentData(OnlineOrderFragment, fetchedData?.onlineOrder);
  const fetchedConfig = getFragmentData(OnlineOrderBranchSettingFragment, fetchedData?.onlineOrderBranchConfiguration);

  if (!fetchedOrder) {
    return;
  }

  if (!fetchedConfig) {
    return;
  }

  return (
    <>
      <OnlineOrderAction onClose={props.onClose} configuration={fetchedConfig} order={fetchedOrder} />
    </>
  );
}

export function OnlineOrderConfirmationQuickAction(props: { orderId: string; onlineOrderState: OnlineOrderState }) {
  const dialogManager = useModalManagerContext();
  const { t } = useTranslate();
  const showQuickActionModal = () => {
    dialogManager.showDialog((onClose) => <OnlineOrderDialog orderId={props.orderId} onClose={onClose} />);
  };

  let title: string;
  let icon: ReactNode;
  switch (props.onlineOrderState) {
    case OnlineOrderState.WaitingForConfirmation:
      icon = <QuestionMarkCircleIcon className="sm:w-4 sm:h-4 w-6 h-6  fill-yellow-500" />;
      title = t('online-order.dashboard.order-status.confirm');
      break;

    case OnlineOrderState.InProgress:
      icon = <BoltIcon className="sm:w-4 sm:h-4 w-6 h-6  fill-yellow-500" />;
      title = t('online-order.dashboard.order-status.in-progress');
      break;

    case OnlineOrderState.Done:
      icon = <CheckCircleIcon className="sm:w-4 sm:h-4 w-6 h-6  fill-green-500" />;
      title = t('online-order.dashboard.order-status.completed');
      break;

    case OnlineOrderState.Canceled:
      icon = <XCircleIcon className="sm:w-4 sm:h-4 w-6 h-6  fill-red-500" />;
      title = t('online-order.dashboard.order-status.cancelled');
      break;

    default:
      title = t('online-order.dashboard.order-status.unknown');
      break;
  }

  return (
    <SecondaryButton
      buttonSize={ButtonSize.medium}
      onClick={(e) => {
        e.stopPropagation();
        showQuickActionModal();
      }}
    >
      <div className="flex items-center justify-center text-xs">
        {icon}
        <div className="hidden sm:block sm:flex sm:flex-row">
          <p>{title}</p>
          <ChevronDownIcon className="inline w-4 h-4" />
        </div>
      </div>
    </SecondaryButton>
  );
}
