import {
  OpeningTimeServiceType,
} from '@client/graphql/types/graphql.ts';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import {
  useDeleteManyTimes,
} from '@client/page/management/opening-time/opening-time-setup/logic/use-delete-many-time.ts';


export default function OpeningTimeDeleteAll(props: {
  branchId: string | undefined;
  service: OpeningTimeServiceType;
  onClose: () => void;
}) {
  const { t } = useTranslate();
  const [deleteManyTimes] = useDeleteManyTimes();
  const handleDelete = async (branchId: string, service: OpeningTimeServiceType) => {
    if (props.branchId) {
      await deleteManyTimes({ variables: { branchId: branchId, service: service } });
    }
    props.onClose();
  };
  return (
    <div className="pt-5 px-8 pb-4 sm:my-2 sm:p-6">
      <div className="sm:flex sm:items-start">
        <div
          className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {t('opening-time.delete-all.title')}
          </Dialog.Title>
          <div className="mt-2 ">
            <p className="text-sm text-gray-500">{t('opening-time.delete-all.message')}{' '}
              <span
                className="font-bold text-red-500">{t('opening-time.delete-all.service.' + props.service.toString().toLowerCase())}</span>
            </p>

          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-row-reverse">
        <PrimaryButton
          data-testid="confirm"
          onClick={() => {
            handleDelete(props.branchId ?? '', props.service).catch((e) => void e);
          }}
        >
          {t('opening-time.delete-all.button')}
        </PrimaryButton>
        <div className="mr-4">
          <SecondaryButton
            onClick={props.onClose}
          >
            {t('common.button.cancel')}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}