import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';
import { client } from '@client/graphql/client.ts';

const QUERY = graphql(`
    query createGoogleCallback($companyId: ID!) {
        googleOAuthUrl(companyId: $companyId)
    }
`);

export function createGoogleOAuthCallback(companyId: string) {
  return client.query({
    query: QUERY,
    variables: { companyId },
  })
}

export function useGoogleOAuthCallback(companyId: string) {
  return useQuery(QUERY, { variables: { companyId } });
}
