import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useCompanies } from '@packages/core/company/hook/use-companies.ts';
import { useNavigate } from 'react-router-dom';

import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import PrimaryButton from 'ui/src/button/primary-button';
import Table from '@packages/ui/table/table.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import Loading from '@packages/ui/loading.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useAuth } from '@client/module/auth/auth-context.ts';

const CompanySelectionPage = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setCompanyID } = useCompanyService();

  const { data, loading, error } = useCompanies(0, 50);

  const onCompanyClick = (id: string) => {
    const companyData = data?.companies.find(company => company.id === id);
    if (companyData) {
      localStorage.setItem('language', companyData.settings.customerDefaultLanguage);
    }
    setCompanyID(id);
    navigate('/', { replace: true });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <div>No companies found</div>;
  }

  return (
    <div className="container max-w-4xl  mx-auto px-4 py-8">
      <Card>
        <CardHeader title="Company">
          {auth?.role.includes('ADMIN') && (
            <PrimaryButton
              onClick={() => {
                navigate('create');
              }}
            >
              Create
            </PrimaryButton>
          )}
        </CardHeader>
        <CardTableContent>
          <Table
            data={data.companies}
            build={(item) => {
              return (
                <TableRow key={item.id}>
                  <TableRowColumn>
                    <div
                      onClick={() => {
                        onCompanyClick(item.id);
                      }}
                    >
                      {item.name}
                    </div>
                  </TableRowColumn>
                </TableRow>
              );
            }}
            loading={loading}
            error={error}
          >
            <TableHeaderColumn>Name</TableHeaderColumn>
          </Table>
        </CardTableContent>
      </Card>
    </div>
  );
};

export default CompanySelectionPage;
