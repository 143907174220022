import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
    mutation UpdateUserNotification($id: ID!, $input: UserNotificationInput!) {
        updateUserNotification(id: $id, input: $input) {
            id
            title
            message
            link
            data {
                color
                type
                pushedNotification
            }
        }
    }
`);

export default function useUpdateUserNotification() {
  return useMutation(MUTATION, {});
}
