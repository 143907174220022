import { useNavigate } from 'react-router-dom';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ChevronRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { ButtonSize } from '@packages/ui/button/button-size.ts';
import { useTranslate } from '@tolgee/react';

export function OnlineOrderStateQuickAction(props: { onlineOrderId: string; navigationPrefix?: string }) {
  const { onlineOrderId } = props;
  const navigate = useNavigate();
  const { t } = useTranslate();
  return (
    <SecondaryButton
      buttonSize={ButtonSize.medium}
      onClick={(e) => {
        e.stopPropagation();

        if (props.navigationPrefix) {
          navigate(`${props.navigationPrefix}/${onlineOrderId}`);
        } else {
          navigate(onlineOrderId);
        }
      }}
      className=""
    >
      <div className="flex items-center justify-center text-xs">
        <QuestionMarkCircleIcon className="sm:w-4 sm:h-4 w-6 h-6 fill-yellow-500" />
        <div className="hidden sm:block sm:flex sm:flex-row">
          <p>{t('common.text.new')}</p>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
      </div>
    </SecondaryButton>
  );
}
