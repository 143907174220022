import { useNavigate, useRouteError } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import { clearAuthStatus } from '@client/module/auth/auth.ts';
import { captureException } from '@sentry/browser';

export function ErrorBoundary() {
  const navigate = useNavigate();
  const error = useRouteError();

  let errorMessage = '';

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  useEffect(() => {
    captureException(error);

    if (error instanceof ApolloError) {
      if (error.message == 'Unauthorized') {
        clearAuthStatus();
        navigate('/login');
      }
    }
  }, [error, navigate]);

  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="">
              <h1 className="my-2 text-gray-800 font-bold text-2xl">
                Looks like you&rsquo;sve found the doorway to the great nothing
              </h1>
              <p className="my-2 text-gray-800">
                Sorry about that! Please visit our homepage to get where you
                need to go.
              </p>
              <p>Error message: &rsquo;s{errorMessage}&rsquo;s</p>
            </div>
          </div>
          <div>
            <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
          </div>
        </div>
      </div>
      <div>
        <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
      </div>
    </div>
  );
}
