import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query customers($companyId: ID!) {
    customers(companyId: $companyId) {
      id
      receiveNewsletter
      customerVariants {
        id
        name
        email
        phone
        createdAt
        nailAppointment {
          ...NailAppointmentItem
        }
      }
    }
  }
`);

export default function useCustomers(companyId: string) {
  return useQuery(QUERY, {
    variables: { companyId },
  });
}
