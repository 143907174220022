import { useCompanies } from '@packages/core/company/hook/use-companies.ts';
import { ReactNode, useEffect } from 'react';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { captureException } from '@sentry/browser';
import DialogAlert from '@packages/ui/dialog-alert.tsx';
import { DialogTitle } from '@headlessui/react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Loading from '@packages/ui/loading.tsx';
import CompanySelection from '@client/page/company/company-selection.tsx';

const FocusedCompany = (props: { children: ReactNode }) => {
  const { company: selectedCompany, setCompanyID, clear, loading: companyLoading } = useCompanyService();

  const { data, loading: companyListLoading, error, refetch } = useCompanies(0, 50);

  useEffect(() => {
    if (companyListLoading || error) {
      return;
    }

    if (!data) {
      return;
    }

    if (!selectedCompany && data.companies.length === 1) {
      setCompanyID(data.companies[0].id);
    }
  }, [data, error, companyListLoading, selectedCompany, setCompanyID]);

  if (companyLoading || companyListLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <DialogAlert>
        <div className="flex flex-col space-y-4 items-center">
          <DialogTitle className="text-xl font-semibold">Error</DialogTitle>
          <p className="line-clamp-4">{error.message}</p>
          <PrimaryButton
            onClick={() => {
              refetch().catch(captureException);
            }}
          >
            Reload
          </PrimaryButton>
        </div>
      </DialogAlert>
    );
  }

  if (!selectedCompany) {
    if (data?.companies.length === 1) {
      return (
        <DialogAlert>
          <div className="flex flex-col space-y-4 items-center">
            <DialogTitle className="text-xl font-semibold">Error</DialogTitle>
            <p className="line-clamp-4">Company is not loaded. Please try again!</p>
            <PrimaryButton
              onClick={() => {
                clear();
                refetch()
                  .then((result) => {
                    if (result.data.companies.length === 1) {
                      setCompanyID(result.data.companies[0].id);
                    }
                  })
                  .catch(captureException);
              }}
            >
              Reload
            </PrimaryButton>
          </div>
        </DialogAlert>
      );
    } else {
      return (
        <CompanySelection
          onSelect={(companyID) => {
            setCompanyID(companyID);
          }}
        />
      );
    }
  }

  return props.children;
};

export default FocusedCompany;
