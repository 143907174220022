import GoogleIntegrationListPage from '@client/page/settings/google/google-integration-list/google-integration-list.page.tsx';

export const SettingsRouter = {
  path: 'settings',
  children: [
    {
      path: 'integration',
      children: [
        {
          path: 'google',
          element: <GoogleIntegrationListPage />,
        },
      ],
    },
  ],
};
