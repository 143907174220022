import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation updateAppointment(
    $id: ID!
    $input: UpdateAppointmentInput
    $pickedProductInput: [PickedProductInput!]
    $userId: ID
  ) {
    updateAppointment(id: $id, input: $input, pickedProductInput: $pickedProductInput, userId: $userId) {
      id
    }
  }
`);

export default function useUpdateAppointment() {
  return useMutation(MUTATION, {
    refetchQueries: ['NailAppointment', 'NailAppointments'],
  });
}
