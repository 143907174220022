import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormItem from '@packages/ui/form/form-item.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { captureException } from '@sentry/browser';
import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import useUpdateMenu from '@client/page/management/menu/menu-edit/logic/use-update-menu.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useParams } from 'react-router-dom';
import { MenuProductPriceMode } from '@client/graphql/types/graphql.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useGetMenu } from '@client/page/management/menu/menu-detail/logic/use-get-menu.ts';

export default function EditMenuTitleModal(props: { onClose: () => void }) {
  const { t } = useTranslate();
  const menuId = useParams().menuId ?? '';
  const serviceID = useParams().serviceId ?? '';
  const { data } = useGetMenu(menuId);
  const form = useForm<{ title: string }>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, control, setError, reset } = form;
  const [update] = useUpdateMenu();
  const { showAlert } = useNotificationCenter();

  useEffect(() => {
    if (data) {
      reset({
        title: data.menu.title,
      });
    }
  }, [data, reset]);

  const onSubmit = async (updateData: { title: string }) => {
    try {
      const id = menuId || serviceID;
      const result = await update({
        variables: {
          menuId: id,
          input: {
            title: updateData.title.trim(),
            allergics: data?.menu.allergics ?? '',
            preferredProductPriceMode: data?.menu.preferredProductPriceMode ?? MenuProductPriceMode.Simple,
          },
        },
      });
      if (result.data) {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('alert.message.update-success', 'Update menu successfully'),
        });
      } else {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('alert.message.update-fail', 'Update menu failed'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof { title: string },
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title={t('menu.menu-edit.menu-edit-title.header', 'Update title')} />
        <CardContent>
          <Controller
            name={'title'}
            control={control}
            rules={{
              required: t('menu.menu-create.form-input.menu-title.validation.required', 'Menu title is required'),
              minLength: {
                value: 3,
                message: t(
                  'menu.menu-create.form-input.menu-title.validation.min-length',
                  'Menu title must be at least 3 characters'
                ),
              },
              maxLength: {
                value: 50,
                message: t(
                  'menu.menu-create.form-input.menu-title.validation.max-length',
                  'Menu title must be at most 50 characters'
                ),
              },
              pattern: {
                value: /^\S.*\S$/,
                message: t(
                  'menu.menu-create.form-input.menu-title.validation.no-space',
                  'Menu title must not start or end with whitespace'
                ),
              },
            }}
            render={({ field }) => (
              <FormItem className="max-w-md" title={t('menu.menu-create.form-input.menu-title.title')}>
                <input
                  {...field}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                />
                {form.formState.errors.title && (
                  <p className="text-red-500 text-xs pt-1">{form.formState.errors.title.message}</p>
                )}
              </FormItem>
            )}
          />
        </CardContent>
        <CardFooter>
          <SecondaryButton onClick={props.onClose}>
            {t('menu.menu-edit.menu-edit-title.button.cancel', 'Cancel')}
          </SecondaryButton>
          <PrimaryButton
            disabled={!form.formState.isValid}
            onClick={() => {
              handleSubmit(onSubmit)()
                .then(() => {
                  props.onClose();
                })
                .catch(captureException);
            }}
          >
            {t('menu.menu-edit.menu-edit-title.button.update', 'Update')}
          </PrimaryButton>
        </CardFooter>
      </Card>
    </div>
  );
}
