import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const QUERY = graphql(`
  query User($companyID: ID!, $userID: ID!) {
    userCompanyRole(companyID: $companyID, userID: $userID) {
      permission
    }

    user(id: $userID) {
      id
      firstName
      lastName
      username
      email
      status
      colorCode
    }
  }
`);

export default function useUserDetail(userID: string, companyID: string) {
  return useQuery(QUERY, {
    variables: {
      userID: userID,
      companyID: companyID,
    },
  });
}
