import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation syncGoogleCalendarService($integrationID: ID!, $service: GoogleCalendarSyncService!, $branchID: ID!) {
    syncGoogleCalendar(integrationID: $integrationID, service: $service, branchID: $branchID)
  }
`);

export function useStartSyncGoogleCalendarService() {
  return useMutation(MUTATION);
}
