export async function forceUpdateServiceWorker() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      // Force the service worker to check for updates
      await registration.update();

      if (registration.waiting) {
        // If there is a waiting service worker, activate it
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });

        // Listen for the state change to reload the app
        registration.waiting.addEventListener('statechange', (event) => {
          // @ts-expect-error: Unreachable code error
          if (event.target?.state === 'activated') {
            window.location.reload();
          }
        });
      } else {
        // If there's no waiting service worker, just reload
        window.location.reload();
      }
    }
  }
}