import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      receiveNewsletter
      customerVariants {
        id
        name
        email
        phone
        createdAt
        nailAppointment {
          id
          startTime
          confirmationStatus
          products {
            id
            appointmentId
            pickedProduct {
              id
              enabled
              title
              description
              images
              code
              menuProduct {
                id
                configurations {
                  id
                  values {
                    id
                  }
                }
              }
              configurations {
                id
                title
                value
                price
              }
            }
          }
        }
      }
    }
  }
`);

export default function useCustomer(id: string) {
  return useQuery(QUERY, {
    variables: { id },
  });
}
