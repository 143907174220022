import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  UserNotificationData,
} from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-data.ts';
import { useTranslate } from '@tolgee/react';

export default function UserNotificationList(props: { items: UserNotificationData[] | undefined }) {
  const { items } = props;
  const { t } = useTranslate();

  return (
    <>
      <div className="flex flex-col">
        {items?.map(item => {
          const notificationType = item.data?.type ?? '';
          const name = item.data?.name ?? '';
          const price = item.data?.price ?? '';
          const time = item.data?.time ?? '';
          let message = '';
          switch (notificationType) {
            //appointment
            case 'NEW_APPOINTMENT':
              message = t('user-notification.list-page.message.new_appointment.message', { name, price, time });
              break;
            case 'CANCELLED_APPOINTMENT':
              message = t('user-notification.list-page.message.cancelled_appointment.message', { name, price, time });
              break;

            //order
            case 'NEW_ONLINE_ORDER':
              message = t('user-notification.list-page.message.new_online_order.message', { name, price, time });
              break;

            //reservation
            case 'WAITING_RESERVATION':
              message = t('user-notification.list-page.message.waiting_reservation.message', { name, time });
              break;
            case 'CANCELLED_RESERVATION':
              message = t('user-notification.list-page.message.cancelled_reservation.message', { name, time });
          }
          const textColorClass = (() => {
            switch (item.data?.color) {
              case 'GREEN':
                return 'text-green-500';
              case 'RED':
                return 'text-red-500';
              case 'YELLOW':
                return 'text-yellow-500';
              default:
                return '';
            }
          })();
          return (
            <Link key={item.id} className="flex flex-col gap-y-3 px-4 py-6 border-b-2 hover:bg-gray-100"
                  to={item.link ?? ''}>
              <p
                className={`font-semibold text-lg ${textColorClass}`}>{t('user-notification.list-page.row.title.' + notificationType.toLowerCase(),
                notificationType)}</p>
              <p className="italic">{message}</p>
              <p className="text-gray-500">{lodash.capitalize(moment(parseInt(item.createdAt)).fromNow())}</p>
            </Link>
          );
        })}
      </div>
    </>
  );
}