import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface PaginationData {
  size: number;
  perPage: number;
  current: number;
  buttonNumberDisplay: number;
}

export default function Pagination(props: { info: PaginationData; setCurrent: (newCurrent: number) => void }) {
  const { info } = props;
  const { size, perPage, current, buttonNumberDisplay } = info;
  const [buttons, setButtons] = useState<number[]>([]);

  useEffect(() => {
    const newButtonCount = Math.ceil(size / perPage);
    const newButtons = Array.from({ length: newButtonCount }, (_, index) => index);
    setButtons(newButtons);
  }, [size, perPage]);

  const moveRight = () => {
    if (current < buttons.length - 1) {
      props.setCurrent(current + 1);
    }
  };

  const moveLeft = () => {
    if (current > 0) {
      props.setCurrent(current - 1);
    }
  };

  return (
    <>
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={moveLeft}
          disabled={current === 0}
          className={classNames(
            'relative inline-flex items-center',
            'rounded-md border border-gray-300 bg-white px-4 py-2',
            'text-sm font-medium text-gray-700 hover:bg-gray-50',
            { 'opacity-50 cursor-not-allowed': current === 0 }
          )}
        >
          Previous
        </button>
        <button
          onClick={moveRight}
          disabled={current >= buttons.length - 1}
          className={classNames(
            'relative ml-3 inline-flex items-center hover:bg-gray-50',
            'rounded-md border border-gray-300 bg-white px-4 py-2',
            'text-sm font-medium text-gray-700',
            { 'opacity-50 cursor-not-allowed': current >= buttons.length - 1 }
          )}
        >
          Next
        </button>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {size > 0 ? (
              <>
                Showing <span className="font-medium">{current * perPage + 1}</span> to{' '}
                <span className="font-medium">{Math.min((current + 1) * perPage, size)}</span> of{' '}
                <span className="font-medium">{size}</span> results
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={moveLeft}
              disabled={current === 0}
              className={classNames(
                'relative inline-flex items-center rounded-l-md px-2 py-2',
                'text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                { 'opacity-50 cursor-not-allowed': current === 0 }
              )}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {buttons.slice(0, buttonNumberDisplay).map((item) => (
              <button
                key={item}
                aria-current="page"
                className={classNames(
                  'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900',
                  'ring-1 ring-inset ring-gray-300 hover:bg-indigo-600 hover:text-white focus:z-20 focus:outline-offset-0',
                  {
                    'bg-indigo-600 text-white': item === current,
                  },
                )}
                onClick={() => {props.setCurrent(item)}}
              >
                {item + 1}
              </button>
            ))}

            <button
              onClick={moveRight}
              disabled={current >= buttons.length - 1}
              className={classNames(
                'relative inline-flex items-center rounded-r-md px-2 py-2',
                'text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                { 'opacity-50 cursor-not-allowed': current >= buttons.length - 1 }
              )}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </>
  );
}
