import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { useTranslate } from '@tolgee/react';
import EmployeeDot from '@client/page/management/appointment/employee/component/employee-dot.tsx';
import EmployeeDetailButtonComponent from '@client/page/management/appointment/employee/component/employee-detail-button.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import UpdateViralUserDialog from '@client/page/management/appointment/employee/component/update-virtual-user-dialog.tsx';
import useDeleteUser from '@client/page/user-company-role/user-detail/logic/use-delete-user.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { AlertConfig, useNotificationCenter } from '@packages/ui/notification/notification-center.ts';

interface UserCompanyRole {
  id: string;
  user: {
    id: string;
    username: string;
    firstName: string;
    email: string;
    colorCode: string;
  };
}

export default function AppointmentEmployeeTable(props: { employees: UserCompanyRole[] }) {
  const { t } = useTranslate();
  const companyId = useCompanyId();
  const { showAlert } = useNotificationCenter();
  const { employees } = props;

  const translate = (template: string, defaultString: string) => {
    return t(template, defaultString);
  };

  return (
    <>
      <Table
        data={employees}
        build={(item) => (
          <TableGroupRow key={item.user.id} showAlert={showAlert} employee={item} companyId={companyId} t={translate} />
        )}
      >
        <TableHeaderColumn className="md:pl-16">{t('appointment.employee.table.name', 'Name')}</TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: {
  employee: UserCompanyRole;
  companyId: string;
  showAlert: (config: AlertConfig) => void;
  t: (template: string, defaultString: string) => string;
}) {
  const { employee, t, companyId, showAlert } = props;
  const dialogManager = useModalManagerContext();
  const [deleteEmployeeMutation] = useDeleteUser();
  const dialog = useDialog();

  const editEmployee = () => {
    dialogManager.showDialog((onClose) => (
      <UpdateViralUserDialog
        user={{ firstName: employee.user.firstName, colorCode: employee.user.colorCode, id: employee.id }}
        onClose={onClose}
      />
    ));
  };

  const deleteEmployee = () => {
    dialog.destructAlert({
      message: t('appointment.employee.table.delete.message', 'Are you sure? this action can be undone'),
      textButton: t('appointment.employee.delete.button', 'Delete'),
      title: t('appointment.employee.delete.title', 'Delete user'),
      onConfirm: () => {
        return deleteEmployeeMutation({
          variables: {
            companyID: companyId,
            userID: employee.user.id,
          },
        })
          .then(() => {
            showAlert({
              status: 'success',
              title: t('appointment.employee.delete.alert.success.title', 'Success'),
              message: t('appointment.employee.delete.alert.success.message', 'Successfully updated virtual user'),
            });
          })
          .catch((e: Error) => {
            showAlert({
              status: 'error',
              title: t('appointment.employee.delete.alert.error.title', 'Success'),
              message: e.message,
            });
          });
      },
    });
  };

  return (
    <>
      <TableRow key={employee.id} className="hover:bg-gray-100">
        <TableRowColumn>
          <div className="flex justify-center items-center gap-3 md:pl-4">
            <EmployeeDot colorCode={employee.user.colorCode} />
            <p className="w-full">{employee.user.firstName}</p>
          </div>
        </TableRowColumn>
        <TableRowColumn>
          <div className="sm:min-w-24 xl:min-w-72"></div>
        </TableRowColumn>
        <TableRowColumn className="flex min-h-16 items-center justify-end">
          <EmployeeDetailButtonComponent editEmployee={editEmployee} deleteEmployee={deleteEmployee} />
        </TableRowColumn>
      </TableRow>
    </>
  );
}
